@import url('https://fonts.googleapis.com/css2?family=Merriweather:wght@300;400;700&family=Roboto:wght@300;400;500;700&family=Ubuntu:wght@300;400;500;700&display=swap');


html {

    height: 100%;



}

body {
    margin: 0;
    height: 100%;


    /*background-color: #e3f3f6;*/
    background-image: url("https://www.transparenttextures.com/patterns/asfalt-light.png");
    /* This is mostly intended for prototyping; please download the pattern and re-host for production environments. Thank you! */


    /*Ниже первый стандартный фон*/
    /*background-color: #c1d9e6;*/
    /*background-image: url("https://www.transparenttextures.com/patterns/binding-dark.png");*/
    /* This is mostly intended for prototyping; please download the pattern and re-host for production environments. Thank you! */
}




.hidden {
    display: none;
}


.wrapper {
    display: flex;
    flex-direction: column;
    height: 100vh;
    min-width: 1200px;


}

.main {

    /*background-image: url("../img/tg_patterns/pattern-28.svg");*/
    /*background-repeat: repeat;*/
    /*background-color: rgba(255, 255, 255, 0.95);*/
    /*background-blend-mode: lighten;*/
    /*background-size: 50%;*/
    display: flex;
    flex-grow: 1;
    /*align-items: flex-start;*/
    /*justify-content: space-around;*/
    /*justify-content: start;*/
    justify-content: center;
    padding: 48px 0px 0px 0px;
    /*border: 3px red solid;*/


}


.welcome-screen {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    background-image: url("../img/tg_patterns/pattern-28.svg");
    background-repeat: repeat;
    background-color: rgba(255, 255, 255, 0.95);
    background-blend-mode: lighten;
    background-size: 50%;
}



.navbar-brand {
    font-family: 'Ubuntu', sans-serif;
    color: #023047;
    font-size: 2rem;

}


.header {

    display: flex;
    justify-content: space-between;
    align-items: center;

    position: fixed;
    width: 100%;
    height: 48px;

    background-color: #3a9dd8;
    /*background-color: #219ebc;*/
    box-shadow: 0 0 5px 0;
    /*padding: 10px;*/
    z-index: 11;


}


.header-main-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 48px;


}



.text-logo {
    font-family: 'Ubuntu', sans-serif;
    font-size: 1.5rem;
    font-weight: 500;
    color: #023047;
    /*margin-top: 7px;*/
    padding-left: 30px;
    /*display: inline-block;*/
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 50vw;

    /*border: 2px red solid;*/



}


.text-logo.mobile {
    width: available;
    /*justify-content: space-between;*/
    min-width: 20%;

    padding-left: 15px;
    font-size: 1.5rem;




}

.helper {
    /*padding-top: 5px;*/
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    /*padding-right: 5px;*/
    padding-right: 10px;


}

.stepper-titles {
    color: #ffffff;
    text-align: end;
}

.agent-button-container {
    display: flex;
    align-items: center;

}

.primary-btn-color-outline {
    border-radius: 8px;
    background-color: #f3f8fb;
    /*border: #0077b6 solid 2px;*/
    color: black;
    font-weight: bold;
    margin-left: 8px;
    margin-top: 1px

}

.primary-btn-color-outline:hover {
    border-radius: 8px;
    background-color: #0077b6;
    /*border: #0077b6 solid 2px;*/
    color: white;
}

.primary-btn-color {
    border-radius: 8px;
    background-color: #0077b6;
    color: white;
}

.secondary-btn-color {
    border-radius: 8px;
    background-color: #6c757d;
    color: white;
}

.secondary-btn-color-border {
    border-radius: 8px;
    background-color: #f3f8fb;
    color: black;
    border: 1px lightgray solid;
}

.btn-main {
    background-color: #0077b6;
    border: 0;
    /*background-color: #ffb703;*/
    display: flex;
    justify-content: center;
    align-items: center;
    /*margin-right: 20px;*/
    height: 30px;

    padding: 0;
    border-radius: 8px;

    font-size: 0.9rem;

}

.btn-main.mobile {

    font-size: 0.7rem;
}


.white-pointer{

    font-family: 'Ubuntu', sans-serif;
    /*align-self: end;*/
    /*padding-bottom: 5px;*/
    font-size: 1.1rem;
    /*margin-left: 195px;*/
}

.white-pointer.mobile{
    font-size: 1rem;
}


.error-message {
    font-family: 'Ubuntu', sans-serif;
    color: #dc3545;
}



.notify-counter-red-border-1 {

    height: 20px;
    width: 20px;
    /*padding-left: 5px;*/
    /*padding-right: 5px;*/
    font-weight: bold;
    font-family: 'Ubuntu', sans-serif;
    background-color: #f1293d;
    border-radius: 50%;
    margin-right: 5px;

}

.notify-counter-red-border-2 {

    height: 20px;
    width: 23px;
    /*padding-left: 5px;*/
    /*padding-right: 5px;*/
    font-weight: bold;
    font-family: 'Ubuntu', sans-serif;
    background-color: #f1293d;
    border-radius: 50%;
    margin-right: 5px;

}

.notify-counter-red-border-3 {

    height: 20px;
    width: 33px;
    padding-left: 5px;
    padding-right: 5px;
    font-weight: bold;
    font-family: 'Ubuntu', sans-serif;
    background-color: #f1293d;
    border-radius: 50%;
    margin-right: 5px;

}


.notify-counter-menu-1 {

    /*height: 20px;*/
    /*width: 20px;*/
    padding: 3px 7px;
    font-weight: bold;
    font-family: 'Ubuntu', sans-serif;
    background-color: #f1293d;
    border-radius: 50%;
    margin-right: 5px;

}

.notify-counter-menu-2 {

    /*height: 30px;*/
    padding: 3px;
    font-weight: bold;
    font-family: 'Ubuntu', sans-serif;
    background-color: #f1293d;
    border-radius: 50%;
    margin-right: 5px;

}

.notify-counter-menu-3 {


    padding: 8px 5px;
    font-weight: bold;
    font-family: 'Ubuntu', sans-serif;
    background-color: #f1293d;
    border-radius: 50%;
    margin-right: 5px;

}



.notify-counter {

    animation: blinker 1.5s linear infinite;
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 0;
    /*font-weight: bold;*/
    font-family: 'Ubuntu', sans-serif;
    font-weight: 700;
    font-size: 1.5rem;
    /*background-color: red;*/
    border-radius: 10px;
    margin-right: 5px;
    color: #023e8a;

}

.notify-counter-user {

    animation: blinker 1.5s linear infinite;
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 0;
    /*font-weight: bold;*/
    font-family: 'Ubuntu', sans-serif;
    font-weight: 700;
    font-size: 1.3rem;
    /*background-color: red;*/
    border-radius: 10px;
    margin-right: 5px;
    padding-bottom: 4px;
    color: #023e8a;

}



@keyframes blinker {

    50% {
        opacity: 0;
    }
}


@keyframes blinker2 {

    50% {
        opacity: 0.5;
    }
}



.footer {


    box-shadow: 0 0 5px 0;
    padding: 10px;
    font-family: 'Ubuntu', sans-serif;
    height: 30px;
    background-color: #3a9dd8;
    /*background-color: #219ebc;*/
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.copy {
    margin-top: 15px;
}




.menu a {
    text-decoration: none;
}


.menu li {
    list-style: none;
    position: relative;
}

.menu__list {
    position: relative;
    display: flex;
    margin-right: 0px;
    padding-left: 0px;
    margin-bottom: 0;

}

.menu__list.mobile {
    display: flex;
    margin-right: 20px;
    padding-left: 0px;

}



.menu__list > li {

    margin: 0 0px 0 0;
}


.menu__link {
    min-width: 110px;
    margin-left: 10px;
    color: white;
    position: relative;
}


.menu__link.mobile {
    min-width: 75px;
    max-width: 75px;
    margin-right: 3px;
    padding: 0px 5px;
}

.sub-menu__list {


    display: none;
    font-size: 0.85rem;
    position: absolute;
    right: 0;
    top: 100%;
    background-color: white;
    border: 1px solid lightgray;
    border-radius: 3px;

    width: fit-content;
    white-space: nowrap;
    min-width: 100px;


}

.sub-menu__list.mobile {


    min-width: 15px;
    width: fit-content;
    overflow: hidden;
    padding-right: 0;


}



.sub-menu__list.mobile > .sub-menu__link {

    display: flex;
    justify-content: end;
    /*position: relative;*/
    /*right: 25px;*/
    padding-top: 6px;
    padding-right: 6px;
    /*padding-bottom: 6px;*/
    /*width: 80%;*/
    font-size: 0.7em;
    width: 100%;





}

.link-arrow {
    display: inline-block;
    transform: scale(0.7, 0.3);;
}


.sub-menu__link {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: black;
    margin-right: 6px;
    z-index: 600;
    padding: 5px;
    width: 100%;


}

.sub-menu__link:hover{
    background-color: rgba(0, 0, 0, 0.08);
}


/*.sub-menu__link > li:hover {*/
/*    background-color: rgba(0, 0, 0, 0.07);*/
/*}*/



.show-dropdown {
    display: flex;
    flex-direction: column;
    position: absolute;
    text-align: right;
    padding: 0;
    /*padding-right: 10px;*/
    /*left: -15px;*/
    z-index: 600;
    cursor: pointer;
}

.show-dropdown.mobile {
    /*right: 30px; !* Или другое значение по вашему усмотрению *!*/
    text-align: right;
    padding: 0;
    z-index: 501;/* Или другие стили, подходящие для мобильного режима */
}


.dossier {

    background-color: #ffe78e;
    /*min-width: 300px;*/
    width: fit-content;
    min-width: 220px;
    height: fit-content;
    padding: 20px;
    border-radius: 7px;
    box-shadow: 0 0px 3px 0;
}


.avatar-dossier {
    /*margin: 25px;*/
    height: 70px;
    width: 70px;
    border: 2px solid lightgray;
    border-radius: 1000px;
}

.avatar-page {
    margin: 25px;
    height: 120px;
    width: 120px;
    border: 3px solid lightgray;
    border-radius: 1000px;
}

.avatar-mini {
    margin: 5px;
    height: 50px;
    width: 50px;
    border: 2px solid lightgray;
    border-radius: 100px;
}

.avatar-micro {
    margin: 5px;
    height: 25px;
    width: 25px;
    border: 2px solid lightgray;
    border-radius: 100px;
}

.name-container {
    /*margin-left: 25px;*/
    margin-top: 20px;
}

.colored-stars {
    color: #FFB703;
}

.rating {
    margin-left: 30px;

}

.main-field {
    display: flex;
    flex-direction: column;
    padding-left: 30px;
    padding-right: 30px;

}


.main-title {
    margin-bottom: 40px;
    margin-left: 20px;
}


.order-feed-wrapper, .outgoing-order-feed-wrapper, .incoming-order-feed-wrapper {
    background-image: url("../img/tg_patterns/pattern-28.svg");
    background-repeat: repeat;
    background-color: rgba(255, 255, 255, 0.95);
    background-blend-mode: lighten;
    background-size: 50%;
    display: flex;
    /*justify-content: center;*/
    /*margin-top: 20px;*/
    /*margin-bottom: 20px;*/
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 100px;
    /*border: 3px black solid;*/
    width: 100%;

}

.order-sorting-section-wrapper {
    width: 25%;
    position: relative;
}

.order-sorting-section {
    position: fixed;
    display: flex;
    flex-direction: column;
    /*position: sticky;*/
    background-color: #f3f8fb;
    /*background-color: #ffe78e;*/
    height: fit-content;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0 0 3px 0;
    /*right: 20px;*/
    top: 68px;
    margin-left: 10px;

}





.filter-spinner-n-reset-button-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /*justify-content: flex-end;*/
    width: 100%;
    margin-top: 10px;
}

.filter-spinner-container {
    display: flex;
    justify-content: center;
    width: 50%;
}

.btn-reset-filter {
    border-radius: 8px;
    /*max-width: 50%;*/
    /*align-self: flex-end;*/
    /*justify-self: flex-end;*/
}

.order-feed {

    /*padding-right: 30px;*/
    width: 75%;
    min-width: 500px;
    padding-right: 20px;
    padding-left: 20px;
    /*border: 3px green solid;*/

}

.order-empty-placeholder {

    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 3px 0;
    border-radius: 7px;
    background-color: #f3f8fb;
    /*background-color: #ffc535;*/
    padding: 20px;
    /*margin-left: 50px;*/
    margin-bottom: 30px;
    /*width: ;*/
    min-height: 100px;

}

.order {


    box-shadow: 0 0 3px 0;
    border-radius: 8px;
    background-color: #f3f8fb;
    /*background-color: rgba(58, 157, 216, 0.65);*/
    /*background-color: #ffc535;*/
    padding: 20px;
    /*margin-left: 50px;*/
    margin-bottom: 30px;
    /*width: 50%;*/
    min-height: 100px;
}

.event-tab {
    /*margin-left: 50px;*/
    box-shadow: 0 0 3px 0;
    border-radius: 8px;
    background-color: #f3f8fb;
    /*background-color: #ffc535;*/
    padding: 20px;
    /*margin-left: 50px;*/
    margin-bottom: 30px;
    width: 100%;
    min-height: 100px;
}


.event-tab-read {
    /*margin-left: 50px;*/
    box-shadow: 0 0 3px 0;
    border-radius: 8px;
    background-color: rgba(211, 211, 211, 0.72);
    /*background-color: #ffc535;*/
    padding: 20px;
    /*margin-left: 50px;*/
    margin-bottom: 30px;
    width: 100%;
    min-height: 100px;
}

.event-tab.green {
    background-color: #29de10;
}

.event-tab.red {
    background-color: #f51549;
}


.event-moder-helper{
    border-radius: 10px;
    padding: 10px;
    background-color: #fff8e4;
}


.hr-event {
    border: none;
    height: 1px; /* Устанавливаем высоту линии */
    background: linear-gradient(to right, rgba(128, 128, 128, 1) 0%, rgba(128, 128, 128, 1) 30%, rgba(128, 128, 128, 0) 100%);
}



.order-top {
    display: flex;
    justify-content: space-between;

}

.order-bottom {
    display: flex;
    justify-content: space-between;
    align-items: end;
}


.event-date {
    margin: 0 0 0 10px
}

.read-event-btn {
    border-radius: 8px;
    margin-left: 10px
}

.order-title {
    max-width: 70%;
}

.safe-deal-btn-container {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
}

.event-date-container {
    display: flex;
    justify-content: flex-end;
}



.order-prefs-details {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
}

.take-btn {

    border-radius: 8px;
    min-width: 150px;
    margin-left: 15px;
}

.green-btn {
    background-color: #42df13;
    border-radius: 8px;
    min-width: 150px;
    margin-left: 15px;
}

.order-sub-bottom {
    display: flex;
    justify-content: right;
    margin-top: 20px;
    margin-left: auto;


}



.order-details-main-wrapper {
    background-image: url("../img/tg_patterns/pattern-28.svg");
    background-repeat: repeat;
    background-color: rgba(255, 255, 255, 0.95);
    background-blend-mode: lighten;
    background-size: 50%;
    display: flex;
    flex-direction: row;
    padding-top: 20px;
    padding-bottom: 20px;
    width: 100%;
    justify-content: center;



}

.order-details-main-wrapper.moderation {
    justify-content: center;
}

.order-details-wrapper {
    display: flex;
    justify-content: center;
    width: 75%;
}

.moderation-order-wrapper{
    display: flex;
    justify-content: center;


    width: 80%;
}

.order-details-moderation {

    padding: 20px;
    border-radius: 7px;
    box-shadow: 0 0 3px 0;
    background-color: #FFB703;
    /*min-width: 850px;*/
    width: 100%;
    justify-self: center;
    height: fit-content;
}


.new-order-wrapper {

    /*background-image: url("https://blog.1a23.com/wp-content/uploads/sites/2/2020/02/pattern-28.svg");*/
    background-image: url("../img/tg_patterns/pattern-32.svg");
    background-color: rgba(255, 255, 255, 0.94);
    background-blend-mode: lighten;
    display: flex;
    flex-direction: row;
    width: 100%;
    /*padding: 0px 50px;*/
    justify-content: center;

}

.new-order-dossier-wrapper, .event-feed-dossier-wrapper, .order-details-dossier-wrapper {

    width: 25%;
    padding-left: 10px;
}

.new-order-form-wrapper {
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;

    width: 75%;
}

.new-order-form, .order-details {


    /*margin-left: 80px;*/
    padding: 20px;
    border-radius: 7px;
    box-shadow: 0 0 3px 0;
    background-color: rgba(58, 157, 216, 0.65);
    /*background-color: #FFB703;*/
    /*min-width: 850px;*/
    width: 85%;
    height: fit-content;
}





form > input {
    min-width: 300px;
    border-radius: 3px;
    border: 1px solid;
    display: inline;
    margin-bottom: 20px;
}

form > select {
    display: inline;
    min-width: 300px;
    margin-bottom: 20px;
    border-radius: 3px;
    border: 1px solid;
}

form > textarea {
    width: 100%;
    min-height: 100px;
    border-radius: 5px;
}

.ap-input {
    margin: 10px 10px 30px 10px;
    margin-left: 15px;


}

.ap-input-number {
    width: 123px;
    margin: 0px 5px 25px 5px;

}

.closest-date {
    margin: 20px 20px 40px 20px;
}


.buttons-and-error-container {
    display: flex;
    flex-direction: row;
    align-items: end;
    justify-content: space-between;
}


.submit-order-btn {
    /*background-color: #023047;*/
    border-radius: 8px;
    justify-self: flex-end;
    margin-left: auto;
}

.btn-container {
    padding-top: 10px;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: start;
}


.error-container {
    display: inline-block;
    margin-left: 20px;
}


.main-login {
    width: 100%;
    margin-top: 15px;
}

.registration-form {
    box-shadow: 0 0 5px 0;
    width: 400px;
    background-color: #ffffff;
    /*margin-top: 50px;*/
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    padding: 40px;

}



.message-center-main {

    /*margin-left: 5vw;*/
    margin-left: auto;
    margin-right: auto;
    border-radius: 20px;
    background-color: #ffb703;
    /*padding: 30px 20px 30px 20px;*/
    padding: 30px;
    width: 95%;
    box-shadow: 0 0 5px 0;

}
.main-shield-title {
    font-family: 'Ubuntu', sans-serif;
    font-size: 2rem;
    color: white;
    /*color: #fff1d4;*/
}


.h3-logo {
    font-family: 'Ubuntu', sans-serif;
    font-size: 1.825rem;
    color: #fff1d4;
}


.h4-logo {
    font-family: 'Ubuntu', sans-serif;
    font-size: 1.5rem;
    color: #fff1d4;
}


.message-center-header {
    display: flex;
    justify-content: center;
    align-items: center;
}

.mc-in-out-switch-button-container {
    margin-right: 100px;

}


.mc-choice-btn {

    border-radius: 8px;
    margin-bottom: 10px;

    width: 150px;
    /*height: 50px;*/
    font-size: 0.75em;
    /*height: 2.5rem;*/
}

.mc-to-order-btn {
    border-radius: 8px;
    margin-bottom: 10px;
    align-self: flex-end;
    /*justify-self: flex-end;*/
    font-size: 0.75em;
}


#otdam {
    /*margin-left: 20px;*/
}


.chat-and-information-mc {
    display: flex;
    flex-direction: row;
    justify-content: space-between;


}


.switch-button-container-mc {
    display: flex;
    flex-direction: column;
    /*background-color: #c1d9e6;*/
    border-radius: 10px;
    padding: 10px 10px 10px 10px;
    margin-top: 20px;
    /*margin-left: 20px;*/
    width: 25%;
    height: 50vh;
    /*max-height: 400px;*/
    /*margin-bottom: 60px;*/
    overflow-y: auto;
    /*border: 2px solid*/
}


.switch-button-container-mc::-webkit-scrollbar {
    width: 12px; /* ширина scrollbar */
}

.switch-button-container-mc::-webkit-scrollbar-track {
    background: wheat; /* цвет дорожки */
}

.switch-button-container-mc::-webkit-scrollbar-thumb {
    background-color: #219ebc; /* цвет плашки */
    border-radius: 20px; /* закругления плашки */
    border: 1px solid wheat; /* padding вокруг плашки */
}


.message-box-and-input-container {
    width: 55%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-bottom: 0px;

}



.button-and-blinker-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}










.user-list-animated {
    animation: show-user-button 0.3s forwards;
}

.arrow-and-button {
    display: flex;
    align-items: center;
    /*justify-content: end;*/
    /*color: white;*/
    font-weight: bold;
    /*width: 100%;*/
    height: 30px;
    cursor: pointer;


}

.order-switch-button {
    height: 35px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    /*margin-top: 5px;*/
    /*margin-bottom: 7px;*/
    margin-right: 0px;
    border-radius: 15px;
    /*align-self: end;*/
    min-width: 200px;
}


.chose-user-button, .doer-button {
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: end;
    min-width: 150px;
    height: 25px;
    border-radius: 10px;
    margin-bottom: 7px;

}



.close-user-btn {

    border: 1px solid #0d99fd;
    display: none;
    height: 25px;
    /*margin-top: 5px;*/
    margin-bottom: 7px;
    margin-right: 5px;
    font-size: 0.7rem;
    /*border-radius: 10px;*/
    width: 30px;
    border-radius: 1px 15px 15px 1px;
    background-color: rgba(255, 255, 255, 0);
    padding: 0;


}

.user-n-close-user-btn{
    display: flex;
    min-width: 50%;
    justify-content: right;

}


.user-n-close-user-btn:hover > .close-user-btn{
    display: inline;
}


.user-n-close-user-btn:hover > .chose-user-button{
    border-radius: 10px 1px 1px 10px;
    min-width: 115px;
}







.close-dialog-btn{

    border: 1px solid #0d99fd;
    display: none;
    height: 35px;
    /*margin-top: 5px;*/
    /*margin-bottom: 7px;*/
    padding: 0;
    /*border-radius: 10px;*/
    width: 48px;
    border-radius: 1px 15px 15px 1px;
    background-color: rgba(255, 255, 255, 0);

}





.btn-order-n-btn-close-container{
    display: flex;
    justify-content: right;
    align-items: center;

}





.order-n-close-order-container{
    display: flex;
    width: 100%;
    justify-content: right;

}


.lotsOfOrders {
    display: flex;
    justify-content: right;
    align-items: center;
    min-width: 30%;

}


.lotsOfOrders:hover > .close-dialog-btn{
    display: inline;


}
.lotsOfOrders:hover > .order-switch-button{
    border-radius: 15px 1px 1px 15px;
    min-width: 152px;

}




.close-dialog-btn:hover, .close-user-btn:hover{
    background-color: rgba(0, 0, 0, 0.68);
    transition: 0.7s;
}





@keyframes show-user-button {
    0% {
        opacity: 0;
        transform: translateY(-100%)
    }
    50% {
        opacity: 0;
        transform: translateY(-50%)

    }
    100% {
        opacity: 1;
        transform: translateY(0)
    }
}


.message-box-mc {
    box-shadow: 0 0 5px 0;
    border-radius: 17px 2px 2px 17px;
    background-color: #8ecae6;
    padding: 10px;
    margin-top: 20px;
    margin-right: 10px;
    margin-left: 0px;
    /*min-height: 300px;*/
    /*max-height: 60vh;*/
    width: 100%;
    height: 50vh;
    overflow-y: auto;
    display: flex;
    flex-direction: column-reverse;
}


.order-information-mc {
    margin-top: 20px;
    width: 20%;
    padding-right: 10px;
    /*border: 2px solid*/
}

.chat-mc {
    display: flex;
    flex-direction: column;

}


.mc-date-self-container {

    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
}

.chat-text-right {
    text-align: end;
}

.chat-form-mc {
    margin-top: 10px;
    display: flex;
    align-self: center;
    width: 100%;

}


.chat-input-mc, .chat-input {
    /*width: 100%;*/
    /*resize: none;*/
    /*height: auto;*/

    width: 100%;
    margin: 0;
    resize: none;
    height: auto;
    border-radius: 8px;
    vertical-align: top;
}

.order-information-mc {
    background-color: #ffe78e;
    padding: 10px;
    border-radius: 10px;
    margin-right: 20px;
    height: fit-content;
}


.order-information-mc-helper {

    margin-bottom: 20px;
}




.information-hard-left {
    font-weight: 500;
    font-family: 'Ubuntu';
    font-size: 1.1rem;
}

.information-right {
    margin-left: 30px;
    /*font-family: 'Montserrat', sans-serif;*/
    font-weight: 600;
    font-size: 0.9rem;
}

.information-right-ws-embedded {
    display: flex;
    margin-left: 0px;
    /*font-family: 'Montserrat', sans-serif;*/
    font-weight: 600;
    font-size: 0.9rem;
    align-items: center;

}


.null-messages {
    align-self: center;
    color: white;

}



.request-space-wrapper {
    background-color: #ffffff;
    /*border: 2px solid black;*/
    border-radius: 8px;
    margin-top: 40px;
    padding: 30px 30px 20px 30px;
}

.request-line {
    padding-left: 15px;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.request-user-info-line {
    font-family: 'Roboto', sans-serif;
    font-weight: 500;

}


.stepper-btn-container {
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    width: fit-content;
}



.approve-request-btn {
    margin-left: 30px;
}


.dissapprove-delete {
    border-radius: 8px;
    margin-right: 20px;
}


.event-delete{
    height: 1.7rem;
    display: flex;
    align-items: center;
    border: black;
}

.event-delete:hover{
    background-color: black;

}



.minishield-button-container {
    display: flex;
}




.stepper-wrapper{
    margin-top: 40px;
}


#stepper-file-input {
    display: none;
}

#order-files-input {
    display: none;
}

#portfolio-files-input {
    display: none;
}


.stepper-date-setter-container{
    display: flex;
}


.stepper-file-attache-button-container{
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
}



.order-done-file-image{
    margin: 10px
}




.one-ready-order-container{
    display: flex;
    justify-content: space-between;
    align-items: center;

}

.users-filename-container{
    display: flex;
    max-width: 70%;
    align-items: center;
    justify-content: center;


}

.order-download-btn {
    margin-left: 20px;
    border-radius: 8px;
}

.error-message-ready-order {
    margin-top: 20px;
}


.order-decisions-button-container{
    display: flex;
}


.order-decision-btn{
    border-radius: 8px;
    margin-right: 20px;
}


.personal-data-wrapper {
    display: flex;
    justify-content: center;
}


.personal-data-balance-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.personal-data-balance-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 5px;
}

.personal-data-balance {
    margin-left: 10px;
    margin-bottom: 0;
}

.events-feed-main-wrapper {
    display: flex;
    width: 100%;
    padding-top: 40px;
    padding-bottom: 20px;
    padding-left: 100px;


    background-image: url("../img/tg_patterns/pattern-28.svg");
    background-repeat: repeat;
    background-color: rgba(255, 255, 255, 0.95);
    background-blend-mode: lighten;
    background-size: 50%;

}


.events-feed-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
    padding-right: 20px;
    padding-left: 20px;
    /*padding-top: 68px;*/

}

.events-feed {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    /*margin-left: 50px;*/
    width: 100%;
    /*align-items: center;*/
    /*margin-left: 30px;*/

}


.feed-update-blinker {
    animation: blinker2 1.5s linear infinite;
    color: #45ec2f;
}



.moderator-room-main-wrapper {
    display: flex;
    width: 100%;

    /*padding-top: 10px;*/
}


.moderation-list-wrapper {

    width: 20%;

}



.moderation-empty-message-wrapper {
    width: 70%;
}

.moderation-list {

    background-color: #fff7d1;
    border-radius: 10px;
    padding: 20px;
    height: fit-content;
}


.moderated-orders-btns-container{
    display: flex;
    flex-direction: column;
}

.moderated-order-btn {
    margin-top: 5px;
}



.moder-decision-btn{
    border-radius: 8px;
    margin-left: 20px;
    min-width: 100px;

}

.rule-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 2px;
}

.btn-sample {
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    width: 100px;
    margin-top: 5px;
}


.table {
    width: 100%;
    border: none;
    margin-bottom: 20px;
    border-collapse: separate;
}

.table.pays {
    width: 60%;
    border: none;
    margin-bottom: 20px;
    border-collapse: separate;
}

.table thead th {
    font-weight: bold;
    text-align: left;
    border: none;
    padding: 10px 15px;
    background: #EDEDED;
    font-size: 14px;
    border-top: 1px solid #ddd;
}
.table tr th:first-child, .table tr td:first-child {
    border-left: 1px solid #ddd;
}
.table tr th:last-child, .table tr td:last-child {
    border-right: 1px solid #ddd;
}
.table thead tr th:first-child {
    border-radius: 20px 0 0 0;
}
.table thead tr th:last-child {
    border-radius: 0 20px 0 0;
}
.table tbody td {
    text-align: left;
    border: none;
    padding: 10px 15px;
    font-size: 14px;
    vertical-align: top;

}
.table tbody tr:nth-child(odd) {
    background: #ffffff;
}

.table tbody tr:nth-child(even) {
    background: #ddd;
}
.table tbody tr:last-child td{
    border-bottom: 1px solid #ddd;
}
.table tbody tr:last-child td:first-child {
    border-radius: 0 0 0 20px;
}
.table tbody tr:last-child td:last-child {
    border-radius: 0 0 20px 0;

}



.user-details-wrapper{
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 20px;

    background-image: url("https://blog.1a23.com/wp-content/uploads/sites/2/2020/02/pattern-28.svg");
    background-attachment: local;
    background-repeat: repeat;
    background-size: 50%;
    /*background-color: rgba(255, 255, 255, 0.97);*/
    background-color: rgba(255, 255, 255, 0.97);
    background-blend-mode: lighten;
}

.user-details-main {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 85%;
    background-color: rgba(58, 157, 216, 0.65);
    border-radius: 8px;
    padding: 30px 40px;
    box-shadow: 0 0 5px 0;
}

.user-details-top {
    display: flex;
}

.user-details-top-left-half {
    display: flex;
    flex-direction: column;
    width: 50%;

}

.user-details-top-right-half {
    display: flex;
    flex-direction: column;
    width: 50%;
    padding: 0px 0px 0px 20px
}


.user-details-bottom {
    display: flex;
    justify-content: flex-end;

}


.user-details-portfolio-container {
    background-color: #ffffff;
    border-radius: 10px;
    margin-top: 20px;
    padding: 20px 20px 20px 20px;
    max-height: 500px;
    overflow-y: auto;
    position: relative;

}

.portfolio-title-container{
    position: sticky;
    top: -20px;
    margin-top: -20px;
    background-color: #ffffff;
    z-index: 1;
    padding: 20px 0 0 0;

}

.portfolio-horizont {
    margin: 0 0 10px 0;
}


.toggle-review-btn {
    border-radius: 8px;
}

.user-details-bottom-left-half {
    width: 50%;
    padding: 10px 0px 0px 0px
}

.private-dates-container {
    display: block;
    align-self: flex-start;
    background-color: white;
}

.private-date-and-read-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 5px;
}



.order-chat-date-and-read-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
}



.user-details-bottom-right-half {
    width: 50%;
    display: flex;
    justify-content: right;
    padding: 10px 0px 0px 20px

}

.avatar-editor-wrapper{
    display: flex;
    align-items: center;

}

.avatar-saved-sign {
    margin-bottom: 0;
    margin-left: 20px;
}

.avatar-n-name-container {
    display: flex;
    align-items: center;
    min-width: 100%;
    width: fit-content;
    justify-content: space-between;
    padding: 0px 20px 0px 0px;
    margin-right: 20px;
    background-color: white;

}


.avatar-n-name-container-left {
    display: flex;
    flex-direction: row;
    align-items: center;
}


.user-details-summary {
    display: flex;
}

.user-details-table tr :last-child {
    text-align: right;
}



.user-details-about-container {
    /*width: 90%;*/
    /*display: flex;*/
    height: fit-content;
    margin-bottom: 20px;
    /*margin-left: 20px;*/
    background-color: white;

}


.user-details-discipline-container {
    width: 60%;
    max-height: 350px;
    overflow-y: auto;
    background-color: white;

}

.user-details-private-info-container {
    align-self: flex-start;
    height: fit-content;
    display: flex;
    flex-direction: column;
    background-color: white;
    /*flex: 1;/*/


}


.review-main-wrapper{
    width: 100%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.review-text-area {
    width: 100%;
    min-height: 150px;
    align-self: center;
    margin-bottom: 10px;
}


.review-symbol-counter{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}


.score-n-sent-btn-container{
    display: flex;
    justify-content: space-between;
}

.review-score-selector{
    width: 150px;
}


.review-send-btn {
    width: 150px;
    align-self: end;
}


.review-length-counter {
    align-self: end;
}

.review-plate{
    display: flex;
    justify-content: space-between;
    margin: 20px 0;
    padding: 20px;
    border-radius: 15px;
    background-color: #f3f8fb;
}


.review-plate-user-link {
    /*text-decoration: none;*/
    color: black;
}


.review-plate-user-link:hover {
    color: gray;
}

.common-review-info{
    background-color: white;
    width: fit-content;
    margin: 20px 0;
    padding: 20px;
    border-radius: 15px;
}

.score-container {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    padding: 30px;
    height: 50px;
    width: 50px;
    background-color: white;
    align-self: center;
}


.material-files-map {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;

}

.add-btn-n-label-new-order {
    display: flex;
    align-items: center;
}

.add-btn-n-label-taken-order {
    display: flex;
    flex-direction: column;
}

.extra-files-add-btn {
    margin-top: 20px;
    align-self: flex-end;
}


.moder-choice-container {
    display: flex;
    justify-content: space-between;
}

.private-chat-wrapper {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    height: 100%;
    /*border: 3px solid red;*/

}


.private-chat-user-list-wrapper::-webkit-scrollbar {
    width: 4px; /* ширина scrollbar */
}



.private-chat-user-list-wrapper::-webkit-scrollbar-track {
    background: #ffffff; /* цвет дорожки */
    /*background: white;*/
    /*border-radius: 0px 20px 20px 0px;*/
}

.private-chat-user-list-wrapper::-webkit-scrollbar-thumb {
    background-color: rgba(128, 128, 128, 0.14); /* цвет плашки */
    border-radius: 8px 8px 8px 8px; /* закругления плашки */
    /*border: 1px solid wheat; !* padding вокруг плашки *!*/
}


.private-chat-user-list-wrapper {
    /*scrollbar-color: rgba(128, 128, 128, 0.14) #ffffff;*/
    /*scrollbar-width: thin;*/
    /*width: 20%;*/
    position: fixed;
    /*border: 2px red solid;*/
    overflow-y: hidden;
    overflow-x: hidden;
    left: 0;
    height: 92.5vh;
    /*padding-left: 5px;*/


}



.private-chat-user-list-wrapper:hover {

    overflow-y: auto;
}

.private-chat-top {

    display: flex;
    flex-direction: column;
    height: 92.5vh;
    /*margin-bottom: 20px;*/
    /*height: 92vh;*/
    width: 80%;
    align-items: flex-start;
    /*border: black 1px solid;*/
    /*padding-left: 30px;*/
    border-left: 1px gray solid;
    border-image: linear-gradient(to bottom, rgba(128,128,128,0) 0%, rgba(128,128,128,1) 50%, rgba(128,128,128,0) 90%);
    border-image-slice: 1;


}


.private-chat-messages-area-empty{
    width: 100%;
    height: 100%;
    display: flex;
    background-image: url("https://blog.1a23.com/wp-content/uploads/sites/2/2020/02/pattern-28.svg");
    background-repeat: repeat-y;
    background-size: 100%;
    background-color: rgba(255, 255, 255, 0.97);
    background-blend-mode: lighten;
}



.private-chat-messages-area-under::-webkit-scrollbar {
    width: 5px; /* ширина scrollbar */
}

.private-chat-messages-area-under::-webkit-scrollbar-track {
    background: #ffffff; /* цвет дорожки */
    /*background: white;*/
    /*border-radius: 0px 20px 20px 0px;*/
}

.private-chat-messages-area-under::-webkit-scrollbar-thumb {
    background-color: rgba(128, 128, 128, 0.14); /* цвет плашки */
    border-radius: 8px 8px 8px 8px; /* закругления плашки */
    /*border: 1px solid wheat; !* padding вокруг плашки *!*/
}


.private-chat-messages-area-under {
    /*это параметры скроллбара для фаерфокса*/
    /*scrollbar-color: rgba(128, 128, 128, 0.14) #ffffff;*/
    /*scrollbar-width: thin;*/

    display: flex;
    flex-direction: column-reverse;
    width: 80%;
    height: 100%;
    background-color: white;
    overflow-y: auto;
    margin-bottom: 10px;



    background-image: url("https://blog.1a23.com/wp-content/uploads/sites/2/2020/02/pattern-28.svg");
    background-repeat: repeat-y;
    background-size: 100%;
    background-color: rgba(255, 255, 255, 0.97);
    background-blend-mode: lighten;

}




.private-chat-messages-area {

    padding-bottom: 20px;
    width: 100%;
    border-radius: 15px;
    display: flex;
    flex-direction: column-reverse;
    background-image: url("https://blog.1a23.com/wp-content/uploads/sites/2/2020/02/pattern-28.svg");
    background-attachment: local;
    background-repeat: repeat-y;
    background-size: 100%;
    background-color: rgba(255, 255, 255, 0.97);
    background-blend-mode: lighten;
}


.private-main-wrapper-left {
    display: flex;
    flex-direction: column-reverse;
    padding: 0px 20px 4px 20px;
}


.private-main-wrapper-right{

    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-end;
    padding: 0px 20px 4px 20px;
}



.private-chat-message-wrapper-left{
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    width: 100%;
}

.private-chat-message-wrapper-right{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
    width: 100%;
}




.private-arrow-svg {
    margin-left: 5px;
    margin-right: 5px;
    display: none
}




.private-chat-message-wrapper-right:hover .private-arrow-svg,
.native-chat-message-wrapper-right:hover .private-arrow-svg,
.native-chat-message-wrapper-center:hover .private-arrow-svg {
    display: block;
}

.private-chat-message-wrapper-left:hover .private-arrow-svg, .native-chat-message-wrapper-left:hover .private-arrow-svg {
    display: block;
}

.private-arrow-svg:hover {
    width: 30px;
    margin-right: 0;

    cursor: pointer;
}






.private-chat-message-left {
    align-self: flex-start;
    display: flex;
    flex-direction: column;
    align-items: start;
    background-color: #f3f8fb;
    padding: 5px 15px 5px 15px;
    margin-top: 5px;
    border-radius: 17px 17px 17px 0px;
    max-width: 75%;
    word-wrap: break-word;
    overflow-wrap: anywhere;

}

.full-private-chat-user-list {
    display: none;

    width: 30%;
    height: 100%;
    /*padding: 20px;*/
    /*margin-left: 25px;*/
    /*border-radius: 15px;*/
    transform: rotateY(90deg);
}


.private-chat-user-list {
    display: flex;
    /*justify-content: center;*/
    /*align-items: center;*/
    /*width: 20%;*/
    /*height: fit-content;*/

    /*position: fixed;*/



    /*left: 0;*/
    /*z-index: 0;*/
    /*margin-left: 25px;*/



}


.lens-and-search-input-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    /*margin-top: 10px;*/
    position: sticky;
    top: 0;
    z-index: 1000;

    height: fit-content;

    /*justify-content: center;*/
    /*border: 3px red solid;*/
    box-sizing: border-box;
    width: 100%;


}


.search-lens {
    margin-bottom: 8px;
}

.lens-stroka {
    background-color: white;
    /*border: 1px red solid;*/
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /*height: 30px;*/
    width: 100%;
    padding-top: 20px;

}

.private-user-search {
    margin-bottom: 10px;
    /*background-color: #ffe78e;*/
    border: 0;
    border-bottom: 1px gray solid;
    border-image: linear-gradient(to right, rgba(128,128,128,0) 0%, rgba(128,128,128,1) 50%, rgba(128,128,128,0) 100%);
    border-image-slice: 1;
    width: 100%;
    height: 20px;


}

/*.cross-container-private {*/
/*    height: 100%;*/
/*    display: flex;*/
/*    align-items: center;*/
/*    justify-content: flex-start;*/
/*    justify-self: flex-end;*/
/*    align-self: flex-end;*/

/*    !*width: 10%;*!*/
/*}*/


.cross-container-private {
    height: 100%;
    display: flex;
    flex-direction: column;
    /*align-items: flex-start;*/
    justify-content: space-between;
    justify-self: flex-end;
    align-self: flex-end;

    width: 23px;
}

.red-cross-event {
    align-self: flex-start;
    cursor: pointer;
}

.red-cross-event:hover {
    background-color: black;
    border-radius: 5px;
}

.red-cross-private {
    display: none;
   justify-self: flex-start;
}

.red-cross-private:hover {
    background-color: black;
    border-radius: 5px;
}

.private-sound-toggler {
    display: none;
    margin-top: auto;
    /*justify-self: flex-end;*/

}



.red-cross-private-delete-attache {
    margin: 0 10px;
    cursor: pointer;
}

.red-cross-private-delete-attache:hover {
    width: 30px;
    background-color: black;
    border-radius: 8px;
    margin-right: 5px;
}

.red-cross-close-fileviewer {
    margin: 0 10px;
    cursor: pointer;


}


.red-cross-close-fileviewer:hover {
    width: 30px;
    background-color: black;
    border-radius: 8px;
    margin-right: 5px;
}


.p-user-private-button {
    /*margin: 0;*/
    margin-top: 0;
    margin-bottom: 0;
    font-family: "Roboto", sans-serif;
    font-size: 0.8em;
    white-space: nowrap; /* Предотвращает перенос текста */
    overflow: hidden; /* Обрезает текст, который не помещается */
    text-overflow: ellipsis; /* Добавляет многоточие для обрезанного текста */
    width: 90%;
    flex-shrink: 1;

}

.p-typing {
    margin-top: 0;
    margin-bottom: 0;
    font-family: "Roboto", sans-serif;
    font-size: 0.75em;
    /*margin-left: 5px;*/
    max-width: 60%;
    white-space: nowrap; /* Предотвращает перенос текста */
    text-overflow: ellipsis; /* Добавляет многоточие для обрезанного текста */
}


.p-doc-viewer-title {
    font-family: "Roboto", sans-serif;
    white-space: nowrap; /* Предотвращает перенос текста */
    overflow: hidden; /* Обрезает текст, который не помещается */
    text-overflow: ellipsis; /* Добавляет многоточие для обрезанного текста */
    margin-left: 0;

}


.private-search-list {
    margin-top: 50px;
    position: absolute;
    max-height: 300px;
    height: fit-content;
    width: 100%;
    background-color: white;
    border-radius: 0 0 10px 10px;
    padding: 5px;
    overflow-y: auto;

}

.private-search-list > :last-child {
    margin: 0
}


.private-search-list::-webkit-scrollbar
{
    width: 5px; /* ширина scrollbar */
}

.private-search-list::-webkit-scrollbar-track {
    background: white; /* цвет дорожки */
    border-radius: 10px;
}

.private-search-list::-webkit-scrollbar-thumb
{
    background-color: lightgray; /* цвет плашки */
    border-radius: 20px; /* закругления плашки */
    /*border: 1px solid rgba(255, 255, 255, 0); !* padding вокруг плашки *!*/
}


.private-forwarding-list-bottom {
    display: flex;
    flex-direction: column;
    padding: 5px;
    justify-content: center;
    flex-wrap: wrap;
    overflow-y: auto;

}

.founded-private-user {
    padding: 3px;
    border-radius: 10px;
}

.founded-private-user:hover {
    background-color: lightgray;
    cursor: pointer
}


.opened-private-dialog-avatar {

    margin-left: auto;
    border: 2px white solid;
    width: 60px;
    margin-right: 10px;
    border-radius: 100px;
}


.front-user-list-card,
.back-user-list-card {

    display: flex;
    flex-direction: column;
    position: absolute;
    width: 100%;

    /*max-height: 100%;*/
    padding: 0px 0px 0px 0px;
    /*border-radius: 15px;*/
    backface-visibility: hidden;
    /*overflow-y: auto;*/
    z-index: 999;
    /*box-sizing: border-box;*/
}


.back-user-list-card::-webkit-scrollbar,
.front-user-list-card::-webkit-scrollbar
{
    width: 12px; /* ширина scrollbar */
}

.back-user-list-card::-webkit-scrollbar-track {
    background: #9dff8e; /* цвет дорожки */
}

.front-user-list-card::-webkit-scrollbar-track {
    background: #ffe78e; /* цвет дорожки */
}

.back-user-list-card::-webkit-scrollbar-thumb,
.front-user-list-card::-webkit-scrollbar-thumb
{
    background-color: #fbc025; /* цвет плашки */
    border-radius: 20px; /* закругления плашки */
    border: 1px solid wheat; /* padding вокруг плашки */
}


.forward-choice-button-container {
    display: flex;
    justify-content: space-around;
}


.private-user-search-spinner-container {

    display: flex;
    justify-content: center; /* Горизонтальное центрирование */
    width: 100%;
}


.private-chat-user-list .front-user-list-card {
    /*background-color: #ffe78e;*/
}

.private-chat-user-list .back-user-list-card {
    /*background-color: #9dff8e;*/
    transform: rotateY(180deg);
}




.private-user-button-container{

    display: flex;
    flex-direction: row;
    align-items: center;
    height: 50px;
    padding: 5px 0 5px 0;
    cursor: pointer;
    overflow: hidden;
    width: 100%;
    border-bottom: 1px gray solid;
    border-image: linear-gradient(to right, rgba(128,128,128,0) 0%, rgba(128,128,128,1) 50%, rgba(128,128,128,0) 100%);
    border-image-slice: 1;
}

.private-user-button-container:hover .red-cross-private {
    display: block;
}
.private-user-button-container:hover .private-sound-toggler {
    display: block;
}


.private-user-button-container:hover .private-message-contact-button{
    border-radius: 6px 0px 0px 6px;
    font-size: 0.8rem;
}

.private-user-button-container:hover .private-message-contact-button.all-corners{
    border-radius: 6px 6px 6px 6px;
    font-size: 0.8rem;
}


.private-user-button-container:hover .delete-private-contact-button {
    display: inline-block;
    border-radius: 0px 6px 6px 0px;

}


.dot-container-private {
    width: 17px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-top: 3px;
}

.delete-private-contact-button {
    font-size: 0.8rem;
    display: none;
    border: 1px solid black;


}

.delete-private-contact-button:hover {
    border: none;
    font-size: 0.87rem;
}


.private-notify-sign {
    animation: blinker 1.5s linear infinite;
    font-size: 1.3rem;
    font-weight: bold;
}


.private-message-contact-button {

    font-size: 0.8rem;
    width: 100%;
    border-radius: 6px 6px 6px 6px;
    margin-left: 3px
}



.private-chat-bottom {
    /*border: 3px red solid;*/
    display: flex;
    /*flex-direction: column;*/
    align-items: flex-start;
    /*justify-content: flex-end;*/
    /*min-height: 20%;*/
    margin-top: 10px;
    width: 100%;
    position: relative;


}


.private-textarea-and-search-container {
    width: 80%;
    margin-right: 20px;
    justify-self: flex-start;
    display: flex;
    /*flex-direction: column;*/
    /*position: absolute;*/



}

.private-screpka-and-send-container{
    justify-self: flex-end;

}

.private-search-list-absolute-container {
    width: 100%;

}

.private-chat-text-input {
    width: 100%;
    resize: none;
    height: auto;
    border-radius: 8px;


}

.private-chat-text-input-search {
    width: 100%;
    resize: none;
    height: auto;
    border-radius: 8px;
    border: 1px solid rgb(232, 51, 51);
}

.private-chat-text-input-search:focus {
    outline: none !important;
    border: 2px solid rgb(232, 51, 51);

}

.private-message-date {
    margin: 0;
    font-size: 0.7rem;
}


#private-chat-input-file {
    display: none;
}

.private-attached-file-container {
    display: flex;
    align-items: center;
}


.private-plug {
    background-color: #0077b6;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 5px 0;
    max-width: 70%;
    color: white;
    font-family: "Roboto", sans-serif;

}



.private-chat-hr{
    color: darkgray;
    position: relative;
    z-index: 10;
    overflow: hidden;
    text-align: center;
}

.private-chat-hr:before,
.private-chat-hr:after {

    content: '';
    display: inline-block;
    width: 100%;
    height: 1px;
    background: #000;
    z-index: -10;
    position: relative;
    vertical-align: middle;
}
.private-chat-hr:before {
    background-color: lightgray;
    right: 15px;
    margin: 30px 0 30px -100%;

}
.private-chat-hr:after {
    background-color: lightgray;
    left: 15px;
    margin: 30px -100% 30px 0;
}


.fake-link:hover {
    cursor: pointer;
}

.answering-message-wrapper {
    display: flex;
    flex-direction: row;
    padding: 0px 10px 0px 10px;
    align-items: center;
    max-width: 50%; /* ограничение ширины до 50% ширины родителя */
    align-self: flex-start; /* выравнивание по левому краю */
    width: auto; /* ширина зависит от содержимого */
}


.answering-message {
    /*display: flex;*/
    border-radius: 8px;
    background-color: #ffe78e;
    border: 0 solid black;
    padding: 7px;
    align-self: flex-start; /* выравнивание по левому краю */
    max-width: 120%;
    width: auto; /* ширина зависит от содержимого */
    word-wrap: break-word;

}

.message-edit-button {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    height: 30px;
    padding: 0px 5px 0px 5px;
}


.p-message-edit-sign {
    margin: 0;
    font-weight: bold;
    font-size: 0.85em;
}

.cancel-answering-button:hover {
    background-color: red;
}

/*.forwarded-message {*/
/*    background-color: #ffe78e;*/
/*    padding: 7px;*/
/*    border-radius: 10px;*/
/*    margin-bottom: 3px;*/
/*    !*max-width: 75%;*!*/
/*    width: fit-content;*/
/*    word-wrap: break-word*/

/*}*/


.answered-message, .forwarded-message{
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    text-align: start;
    background-color: #ffe78e;
    /*max-width: 90%;*/
    padding: 5px 7px;
    width: auto; /* ширина зависит от содержимого */
    min-width: 150px;
    /*max-width: 75%;*/
    margin-top: 6px;
    margin-bottom: 6px;
    color: black;


}

.answered-right {
    align-self: flex-end; /* выравнивание по ghfdjve краю */
}

.answered-left {
    align-self: flex-start; /* выравнивание по левому краю */
}


.nick-and-go {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-width: 100px;


}


.marked-message {
    background-color: rgba(0, 0, 0, 0.25);
}


.highlighted-message {
    background-color: rgba(0, 0, 0, 0.25);
    animation: fadeOut 5s forwards;
}

@keyframes fadeOut {
    to {
        background-color: rgba(0, 0, 0, 0);
    }
}

.private-chat-message-right {
    align-self: flex-end;
    display: flex;
    flex-direction: column;
    align-items: end;
    background-color: #8ac6e6;
    padding: 5px 15px 5px 15px;
    margin-top: 5px;
    border-radius: 17px 17px 0px 17px;
    /*width: fit-content;*/
    max-width: 75%;
    word-wrap: break-word;
    overflow-wrap: anywhere;
}



.private-message-forwarded-container {
    display: flex;
    flex-direction: column;
    /*border: 3px solid red;*/
    /*max-width: 75%;*/

}






.link-from-roller {
    font-size: 0.75rem;
    text-decoration: none;
    color: black;
}


.work-message-wrapper-right {
    display: flex;
    flex-direction: column;
}

.work-message-wrapper-left {
    display: flex;
    flex-direction: column;
}

.work-message-wrapper-center {
    display: flex;
    flex-direction: column;
}

.message-box-native-wrapper {
    display: flex;
    justify-content: flex-end;
}


.empty-wrapper {
    box-shadow: 0 0 3px 0;
    border-radius: 7px;
    padding: 20px;
    margin-bottom: 30px;
    width: 100%;
    min-height: 100px;
    background: repeating-linear-gradient(
            45deg,
            #000000,
            #000000 40px,
            #eaca2e 40px,
            #eaca2e 80px
    );

}


.price-offer-modal-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.price-offer-label-n-input-container {
    display: flex;
    justify-content: center;
}


.user-search-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
}



.user-search-dossier-container{
    width: 20%;

}


.admin-search-work-place {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
}


.user-search-work-place {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    padding-top: 30vh;
    padding-bottom: 50px;
    background-image: url("../img/tg_patterns/pattern-28.svg");
    background-repeat: repeat;
    background-color: rgba(255, 255, 255, 0.95);
    background-blend-mode: lighten;
    background-size: 50%;
}

.upper-search {
    padding-top: 10vh;
}

.event-setup-bar-wrapper {
    width: 25%;
    display: flex;
    justify-content: flex-end;
    padding-right: 20px;
}



.event-setup-bar {
    position: fixed;
    top: 88px;
    /*right: 0;*/
    /*margin-top: 50px;*/
    /*margin-left: 100px;*/
    display: flex;
    flex-direction: column;
    /*align-items: center;*/
    justify-content: center;
    width: fit-content;
    height: fit-content;
    border-radius: 10px;
    box-shadow: 0 0 3px 0;
    background-color: #f3f8fb;
    padding: 20px;

}


.event-filter-select {
    width: 100%
}

.event-filter-input {
    width: 100%;
}

.user-search-main-wrapper {
    min-width: 40%;
    width: fit-content;
    display: flex;
    align-items: center;
}

.user-search-main {

    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    height: fit-content;
    border-radius: 8px;
    box-shadow: 0 0 3px 0;
    background-color: #3a9dd8;
    padding: 10px;
}

.user-search-tip {
    display: none;
    position: absolute;
    background-color: #caf0f8;
    border-radius: 8px;
    padding: 10px;
    max-width: 400px;
}


.user-search-tip-image:hover ~ .user-search-tip {
    display: block;
}


.user-search-query-input {
    width: 100%;
}

.user-search-admin-button-container {
    display: flex;
    flex-wrap: wrap;
    max-width: 40vw;


}

.user-search-admin-button-container > button {
    margin-left: 10px;
    margin-bottom: 10px;
}




.founded-user-tab {
    border-radius: 15px;
    box-shadow: 0 0 3px 0;
    width: 60%;
    background-color: rgba(58, 157, 216, 0.65);
    padding: 20px;
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    /*align-items: center;*/
    /*justify-content: space-between;*/
}

.founded-user-tab-top {
    border-radius: 15px;
    /*background-color: #219ebc;*/
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.founded-user-tab-bottom {
    /*border-radius: 15px;*/
    /*background-color: #219ebc;*/
    display: flex;
    flex-direction: column;
    /*align-items: center;*/
    /*justify-content: center;*/
    color: white;
}


.unreliable-user-about{
    background-color: white;
    border-radius: 10px;
    color: black;
    padding: 10px
}

.admin-founded-user-tab {
    border-radius: 15px;
    box-shadow: 0 0 3px 0;
    width: 80%;
    background-color: #219ebc;
    padding: 20px;
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}


.admin-founded-user-ava-name {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.admin-founded-user-buttons-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: start;
    max-width: 55%;

}
.admin-founded-user-buttons-container > button {
    margin-bottom: 10px;
    margin-right: 10px;/* Расстояние между дочерними элементами (можете настроить под свои нужды) */
}


.avatar-search {
    margin: 25px;
    height: 100px;
    width: 100px;
    border: 3px solid lightgray;
    border-radius: 1000px;
}


.search-empty-tab {
    border-radius: 8px;
    box-shadow: 0 0 3px 0;
    width: 50%;
    background-color: rgba(58, 157, 216, 0.65);
    color: white;
    padding: 20px;
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}


.untrustworthy-sign {
    border-radius: 15px;
    padding: 10px;
    margin-right: 20px;
    position: relative;
    display: inline-block;
    background-color: red;
    border: 3px black solid;
}


.invisible-tip {
    padding: 20px;
    position: absolute;
    top: 150%;
    left: 100%;
    transform: translateY(-50%);
    background-color: #333;
    color: white;
    border-radius: 4px;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s ease-in-out;
}


.tip-trigger:hover + .invisible-tip {
    opacity: 1;
}


.admin-office-main-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-left: 20px;
    padding-right: 20px;
}


.admin-office-switch-container {
    display: flex;
    flex-direction: row;
    align-self: center;
}

.admin-switch-btn {
    width: 170px;
    height: 40px;
    margin: 10px;
}

.admin-classifier-wrapper {
    display: flex;
    flex-direction: column;
}


.admin-classifier-science-discipline-container {
    display: flex;
    /*flex-direction: column;*/


}


.classifier-and-search-discipline-container {
    display: flex;
    align-items: flex-start;

}

.classifier-and-errors-container {
    display: flex;
    /*align-items: center;*/
    width: 60%;
    flex-direction: column;
}

.new-order-classifier-wrapper {
    display: flex;
    flex-direction: column;

    width: 100%;
    border: 1px rgba(0, 0, 0, 0.37) solid;
    box-shadow: 0px 0px 3px 0px;
    padding: 10px;
    border-radius: 10px;
}

.personal-classifier-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    border: 1px rgba(0, 0, 0, 0.37) solid;
    box-shadow: 0px 0px 3px 0px;
    padding: 10px;
    border-radius: 15px;
    margin-bottom: 20px;
}


.classifier-section-container {
    display: flex;
    width: 100%;
    align-items: center;

}


.discipline-react-select {
    width: 100%;
    /*display: flex;*/
    /*flex-direction: column;*/
    /*flex: 1; !* Используйте flex-grow для заполнения доступного пространства *!*/
    /*overflow: hidden; !* Обрезает содержимое, которое не помещается *!*/
}


.new-order-classifier-science-discipline-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;


}



.save-blinker-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.arbitration-main-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;

}

.arbitr-info-shield {
    /*background-color: #ffe78e;*/
    height: fit-content;
    border-radius: 8px;
    padding: 25px;
    max-width: 25%;
    margin-right: 20px;
    overflow-x: hidden;

}


.arbitr-info-shield-ws-embedded {
    /*background-color: #ffe78e;*/
    height: fit-content;
    /*border-radius: 8px;*/
    margin-top: 30px;
    padding: 5px;
    max-width: 20%;
    padding-left: 10px;
    overflow-x: hidden;
    width: 100%;

}


.p-reset {
    margin: 0;
    width: fit-content;
}


.archived-order-selector {
    width: 100%;

}

.order-placing {
    display: flex;
    flex-direction: row;
}



.progress-bar-container {
    position: relative;
    width: 100%;
    height: 20px;
    background-color: #f0f0f0;
    margin-top: 10px;
    border-radius: 5px;
    overflow: hidden;
}

.progress-bar {
    height: 100%;
    background-color: #4caf50;
    transition: width 0.3s ease;
}

.progress-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #000000;
}



.invocation-message {
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    background-color: #ffe78e;
    border: 0 solid black;
    padding: 7px;
    align-self: flex-start; /* выравнивание по левому краю */
    max-width: 120%;
    width: auto; /* ширина зависит от содержимого */
    word-wrap: break-word;

}

.invocator-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}


.reaction-add-container {
    display: flex;
    background-color: white;
    border-radius: 15px;
    padding: 10px;
    box-shadow: 0 0 3px 0;
    margin-left: 10px;
    position: absolute;
    z-index: 1000;
}

.edit-mark-wrapper {
    display: flex;
    margin-left: auto;

}


.reaction {
    cursor: pointer;
}

.reaction-add-container.visible {
    display: block;

}


.ws-message-bottom {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;
    align-self: flex-start;
}

.reaction-container {
    display: flex;
    /*flex-direction: row;*/
    /*width: 100%;*/
    /*justify-content: end;*/

}

.reaction-and-list-container {
    display: flex;
    font-size: 0.6rem;
    /*align-items: flex-end;*/

}

.reaction-under-message {
    background-color: white;
    border-radius: 100px;
    padding: 3px;
    margin-right: 2px;
    margin-top: 5px;
    cursor: pointer;

}

/*.reaction-and-list-container:hover > .reaction-list {*/
/*    display: flex;*/
/*}*/

.reaction-list.visible {
    display: flex;
}


.reaction-list {
    display: none;
    position: absolute;
    flex-direction: column;
    background-color: white;
    border-radius: 10px;
    padding: 5px;
    margin-left: 30px;
    z-index: 10000;
    max-height: 300px;
    overflow-y: auto;
}

.reaction-list-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}






.private-compose-form {
    display: flex;
    align-items: center;
    /*justify-content: space-between;*/


}




.private-forward-and-close-buttons-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    white-space: nowrap;
    align-self: center;
}

.private-cancel-forwarding-btn {
    cursor: pointer;
    margin-left: 10px;
    margin-top: 5px;
    margin-bottom: 5px;

}


.search-message-control-container {
    display: flex;
    flex-direction: row;
    padding: 3px;
    /*border-radius: 10px;*/
    align-items: center;
    justify-content: flex-start;
    /*width: 170px;*/
    margin-bottom: 2px;

}

.sound-message-control-container {
    display: flex;
    flex-direction: row;
    padding: 3px;
    border-radius: 10px;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 2px;

}




.search-list-container {
    display: flex;
    flex-direction: column-reverse;
    position: absolute;
    width: 79.5%;
    bottom: 100%;
    max-height: 300px;
    overflow-y: scroll;
    left: 0/* ваше значение */;
    background-color: white/* ваш цвет фона */;
    border: 1px solid /* ваш цвет рамки */;
    padding: 5px/* ваше значение */;
    z-index: 1; /* Гарантирует, что список пользователей будет поверх других элементов */
}


.search-lens-messages {

}

.animated-search-indicator {


    opacity: 0;
    transition: transform 0.5s ease-out, opacity 0.5s ease-out;
}


.animated-search-indicator.visible {
    opacity: 1;
    transform: translateX(10px); /* Измените значение, чтобы настроить анимацию по вашему желанию */
}


.search-date-and-name-container {
    display: flex;
    flex-direction: column;
    min-width: 20%;

}




.admin-classifier-wrapper {
    display: flex;
    flex-direction: column;
    background-color: #fba625;
    width: 50%;
    border: 1px rgba(0, 0, 0, 0.37) solid;
    box-shadow: 0px 0px 3px 0px;
    padding: 10px;
    border-radius: 15px;
    margin-top: 20px;
}


.adding-discipline-monitor {
    display: flex;
    flex-direction: column;
    width: 50%;
}

.admin-dis-management-buttons {
    display: flex;
    justify-content: flex-end;
    width: 50%;
}


.welcome-btn-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 25px;
}


.welcome-btn {
    width: 170px
}


.search-mode-selector {
    display: flex;
    justify-content: space-evenly;
    padding: 10px;
    width: 40%;
    margin-bottom: 30px;
}

.absent-order-empty-message {
    display: flex;
    justify-content: space-between;
    align-items: center;
}


.private-order-user-list {
    margin-bottom: 20px;
    position: relative;
}



.private-order-user-input {
    width: auto;
}

.founded-user-list {
    padding: 0px 5px;
    position: absolute;
    top: 100%; /* Отступ от верхней границы родительского элемента */
    left: 0; /* Выравнивание слева по отношению к родительскому элементу */
    z-index: 1; /* Устанавливаем z-index для управления порядком слоев */
    background-color: white; /* Цвет фона */
    border: 1px solid #ccc; /* Граница */
    border-top: none; /* Не показывать границу сверху */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Тень */
    max-height: 200px; /* Максимальная высота, чтобы предотвратить излишнюю прокрутку */
    overflow-y: auto; /* Включаем вертикальную прокрутку при необходимости */
}


.potential-doer-name{
    margin-bottom: 0;
    margin-left: 20px;
    font-weight: bold;
}


.invite-delete-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20px;
    font-size: 14px;
    padding: 2px 4px;
}


.bar-chart-wrapper {
    width: 100%;
    /*background-color: #fceba9;*/
    background-color: #219ebc;
    border-radius: 10px;
    margin-top: 20px;
    position: relative;
    height: fit-content;
    padding: 15px;
}


.bar-chart-container {
    width: 100%;
    display: flex;


}

.bar-names-container {
    display: flex;
    flex-direction: column;
}

.single-bar-name-container {
    height: 50px;
}

.bars-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 80%;
    margin-left: 15px;

}

.single-bar-container {
    height: 50px;
    width: 100%;
}

.bar {

    height: 30px;
    /*background-color: #29aad2;*/
    margin-bottom: 15px;
    padding: 6px;
    border-radius: 8px;
}

.unreg-review-btn {
    border-radius: 8px;
    margin-top: 20px;
    align-self: flex-end;
}


.order-description-container {
    width: 100%;
}


.p-from-text-processor {
    margin: 0;

    /*font-size: 0.85rem;*/
}


.empty-message-holder{
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url("../img/tg_patterns/pattern-28.svg");
    background-repeat: repeat;
    background-color: rgba(255, 255, 255, 0.95);
    background-blend-mode: lighten;
    background-size: 50%;
}


.decision-date-container {
    display: flex;
}


.decision-p {
    margin: 0 0 0 10px;
}

.donate-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-image: url("https://blog.1a23.com/wp-content/uploads/sites/2/2020/02/pattern-28.svg");
    background-repeat: repeat;
    background-size: 50%;
    background-color: rgba(255, 255, 255, 0.97);
    background-blend-mode: lighten;
    width: 100%;
    padding-top: 30px;
}

.donate-main {
    display: flex;
    flex-direction: column;
    width: fit-content;
    padding: 20px;
    border-radius: 8px;
    height: fit-content;
    background-color: white;

}

.old-pay-form-container {


}

.old-pay-button {
    width: 100%;
    margin-top: 15px;
}

.payment-viewer-filter-container {
    display: flex;
    align-items: center;
}

.payment-filter-user-list {
    margin-left: 20px;
    position: relative;

}

.payment-filter-user-input {
    width: auto;
    height: 26px;
}

.payment-filter-btn {
    height: 26px;
    margin-left: 20px;
    display: flex;
    align-items: center;
}


@-moz-document url-prefix() {
    .private-chat-messages-area-under {
        scrollbar-color: rgba(128, 128, 128, 0.14) #ffffff;
        scrollbar-width: thin;
    }

    .private-chat-user-list-wrapper {
        scrollbar-color: rgba(128, 128, 128, 0.14) #ffffff;
        scrollbar-width: thin;
    }

    .ws-chat-embedded-canvas-under {
        scrollbar-color: rgba(128, 128, 128, 0.14) #ffffff;
        scrollbar-width: thin;
    }

}