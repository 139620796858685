.new-center-main-wrapper {
    width: 100%;
    height: 92.5vh;
    display: flex;
    flex-direction: column;


}

.new-center-main {

    display: flex;
    flex-direction: column;
    /*margin-left: 5vw;*/
    margin-left: auto;
    margin-right: auto;
    /*border-radius: 20px 20px 0px 0px;*/
    /*background-color: #ffb703;*/
    /*padding: 30px 20px 30px 20px;*/
    padding: 0px 0px 0px 0px;
    width: 100%;
    /*box-shadow: 0 0 5px 0;*/
    /*height: 65vh;*/
    height: 92.5vh;
    /*height: 100%;*/





}

/*.new-center-shadow-wrapper{*/
/*    !*box-shadow: 0 0 5px 0;*!*/
/*    !*border-radius: 20px;*!*/
/*    border: 3px purple solid;*/
/*    height: 100%;*/
/*}*/



.new-center-in-out-switch-button-container {
    display: flex;
    flex-direction: row;
    justify-content: center;

}

.new-center-middle-area {
    display: flex;
    flex: 1;
    flex-direction: row;
    height: 44vh;

}

.new-center-order-info-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 20%;
    padding-top: 20px;
    padding-left: 20px
}

.mc-result-info-container {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.to-order-link {
    align-self: flex-end;
    justify-self: flex-end;
    margin-left: 50px;
}

.new-center-info {
    /*background-color: #ffe78e;*/
    border-radius: 20px;
    /*padding: 15px;*/
    /*margin-top: 20px;*/
    width: 100%;
    display: flex;
    align-items: flex-start;
}

.new-center-message-box-container-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 58%;
    height: 100%;
    /*padding: 0px 10px 10px 10px;*/

    /*border: 2px red solid*/

}

.new-center-answering-n-input-container {
    padding-left: 20px;
    padding-top: 10px;
}

.new-center-message-box-container {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    height: 100%;
    /*padding: 0px 10px 10px 10px;*/
    /*border: 2px green solid*/

}

.new-center-message-box-empty {
    width: 100%;
    height: 100%;
    display: flex;
    background-image: url("https://blog.1a23.com/wp-content/uploads/sites/2/2020/02/pattern-28.svg");
    background-repeat: repeat-y;
    background-size: 100%;
    background-color: rgba(255, 255, 255, 0.97);
    background-blend-mode: lighten;
}

.new-center-message-box-under::-webkit-scrollbar {
    width: 5px; /* ширина scrollbar */
}

.new-center-message-box-under::-webkit-scrollbar-track {
    background: #ffffff; /* цвет дорожки */
    /*background: white;*/
    /*border-radius: 0px 20px 20px 0px;*/
}

.new-center-message-box-under::-webkit-scrollbar-thumb {
    background-color: rgba(128, 128, 128, 0.14); /* цвет плашки */
    border-radius: 8px 8px 8px 8px; /* закругления плашки */
    /*border: 1px solid wheat; !* padding вокруг плашки *!*/
}



.new-center-message-box-under {
    margin-top: 10px;
    /*border-radius: 20px 15px 15px 20px;*/
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
    height: 100%;
    background-color: white;
    overflow-y: auto;


}

.new-center-message-box {

    /*border-radius: 20px 20px 20px 20px;*/
    padding: 0 20px;
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
    background-image: url("https://blog.1a23.com/wp-content/uploads/sites/2/2020/02/pattern-28.svg");
    background-attachment: local;
    background-repeat: repeat-y;
    background-size: 100%;
    background-color: rgba(255, 255, 255, 0.97);
    background-blend-mode: lighten;
    border-left: gray 1px solid;


}


.mb-min-height {
    min-height: 100%;
}


.message-center-order-list-wrapper {
    width: 20%;
    margin-top: 20px;
    height: 100%;
    position: relative;

}


.new-center-order-list-container {
    position: fixed;
    display: flex;
    flex-direction: column;
    max-height: 78vh;
    align-items: flex-start;
    overflow-y: auto;
    overflow-x: hidden;
    top: 100px;
    z-index: 501;

}

.mc-mode-selector {
    /*align-self: flex-end;*/
    position: fixed;
    margin-bottom: 10px;
    margin-left: 20px;
}

.new-center-order-list-container::-webkit-scrollbar {
    width: 6px; /* ширина scrollbar */
}

.new-center-order-list-container::-webkit-scrollbar-track {
    /*background: #ffb703; !* цвет дорожки *!*/

}

.new-center-order-list-container::-webkit-scrollbar-thumb {
    background-color: lightgray; /* цвет плашки */
    border-radius: 5px; /* закругления плашки */
    /*border: 1px solid wheat; !* padding вокруг плашки *!*/
}


.new-center-footer {
    background-color: #ffb703;
    padding: 10px 20px 30px 20px;
    border-radius: 0px 0px 20px 20px;

}


.new-center-input-container-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 15vh;
    /*border: orangered 3px solid;*/
    justify-content: flex-end;
    align-items: center;
    padding-bottom: 5px;
    position: relative;
    z-index: 500;


}

.mc-search-hint {
    align-self: flex-start;
    justify-self: flex-start;
}

.new-center-input-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 60%;
    padding-left: 5px;
    padding-right: 28px;

}

.message-center-textarea-n-close-button-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
}



.answering-message-wrapper-message-center{
    display: flex;
    flex-direction: row;
}





.chat-center {

    width: fit-content;
    max-width: 75%;
}



.work-message-wrapper-right:hover .private-arrow-svg,
.work-message-wrapper-left:hover .private-arrow-svg,
.work-message-wrapper-center:hover .private-arrow-svg {
    display: block;
}


.work-message-wrapper-right{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
    width: 100%;
}


.work-message-wrapper-left{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-end;
    width: 100%;
}


.work-message-wrapper-center{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
    width: 100%;
}


.new-center-info-order-list {
    width: 100%;
    border: 3px blue solid;
}


.work-read-marker {
    display: flex;
    justify-content: flex-end;


}

.work-read-img {
    background-color: white;
    padding: 3px;
    border-radius: 5px;
}


.p-order-choice-message-center {
    /*margin: 0;*/
    margin-top: 0;
    margin-bottom: 0;
    font-family: "Roboto", sans-serif;
    font-size: 0.8em;
    white-space: nowrap; /* Предотвращает перенос текста */
    overflow: hidden; /* Обрезает текст, который не помещается */
    text-overflow: ellipsis; /* Добавляет многоточие для обрезанного текста */
    width: 80%;
    /*margin-right: auto;*/


}

.p-user-choice-message-center {
    margin-top: 0;
    margin-bottom: 0;
    font-family: "Roboto", sans-serif;
    font-size: 0.7em;
    white-space: nowrap; /* Предотвращает перенос текста */
    overflow: hidden; /* Обрезает текст, который не помещается */
    text-overflow: ellipsis; /* Добавляет многоточие для обрезанного текста */
    max-width: 80%;
}

.p-order-choice-message-center-inactive {
    margin: 0;
    align-self: flex-end;
    justify-self: flex-end;
    font-family: "Roboto", sans-serif;
    font-size: 0.8em;
    white-space: nowrap; /* Предотвращает перенос текста */
    overflow: hidden; /* Обрезает текст, который не помещается */
    text-overflow: ellipsis; /* Добавляет многоточие для обрезанного текста */
    width: 60%;
    text-align: right;

}


.chat-name-first-message-inactive-holder {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /*border: 1px red solid;*/
    /*flex-grow: 1;*/
}

.dot-container-message-center {
    width: 17px;
    height: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    padding-top: 3px;
}

.dot-container-user-message-center {
    width: 17px;
    margin-left: 50px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.cross-container-message-center {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    justify-self: flex-end;
    align-self: flex-end;

    /*width: 10%;*/
}



.lots-of-orders-out-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-bottom: 1px gray solid;
    border-image: linear-gradient(to right, rgba(128,128,128,0) 0%, rgba(128,128,128,1) 50%, rgba(128,128,128,0) 100%);
    border-image-slice: 1;
}

.lotsOfButtons {
    display: flex;
    flex-direction: row;
    /*justify-content: flex-end;*/
    align-items: center;
    height: 50px;
    padding: 5px;
    cursor: pointer;
    overflow: hidden;
    width: 100%;

}

.svg-closing-cross-message-center {
    display: none;
}

.svg-closing-cross-message-center:hover {
    background-color: black;
    border-radius: 5px;
}

.lotsOfButtons:hover .svg-closing-cross-message-center{
    display: block;


}
.lotsOfButtons:hover > .order-switch-button{
    border-radius: 15px 1px 1px 15px;
    min-width: 152px;

}

.mc-avatar{
    width: 35px;
    height: 35px;
    margin-right: 10px;
    border-radius: 100px;

}


.mc-p-hint {
    white-space: pre-line;
    padding: 20px;
}