.message-box-native-wrapper::-webkit-scrollbar,
.message-box-native-under::-webkit-scrollbar,
.chosen-discipline-container::-webkit-scrollbar {
    width: 5px; /* ширина scrollbar */

}

.message-box-native-wrapper::-webkit-scrollbar-track,
.message-box-native-under::-webkit-scrollbar-track,
.chosen-discipline-container::-webkit-scrollbar-track {
    /*background: wheat; !* цвет дорожки *!*/
    background: #ffffff; /* цвет дорожки */
    border-radius: 8px 8px 8px 8px;

}

.message-box-native-wrapper::-webkit-scrollbar-thumb,
.message-box-native-under::-webkit-scrollbar-thumb,
.chosen-discipline-container::-webkit-scrollbar-thumb {
    background-color: rgba(128, 128, 128, 0.6); /* цвет плашки */
    border-radius: 8px 8px 8px 8px; /* закругления плашки */
    /*border: 0px solid wheat; !* padding вокруг плашки *!*/
}


.personal-data-wrapper {

    width: 100%;
    background-image: url("https://blog.1a23.com/wp-content/uploads/sites/2/2020/02/pattern-28.svg");
    background-attachment: local;
    background-repeat: repeat;
    background-size: 50%;
    background-color: rgba(255, 255, 255, 0.97);
    background-blend-mode: lighten;
    padding: 20px 0;


}

.personal-data-shield {
    display: flex;
    flex-direction: column;
    /*justify-content: space-between;*/
    background-color: rgba(58, 157, 216, 0.65);
    width: 85%;
    padding: 30px;
    border-radius: 6px;
    box-shadow: 0 0 3px 0;


}

.personal-data-underwrapper {
    display: flex;

}

.personal-data-top {
    display: flex;
    width: 100%;
}

.personal-data-left-half {
    width: 50%;
    padding-right: 10px;
}

.personal-data-right-half {
    width: 50%;
    padding-left: 10px;
    /*overflow-y: auto;*/

    display: flex;
    flex-direction: column;


}


.personal-data-input {
    display: block;
    margin-bottom: 0px;
}

.personal-data-label {
    margin-bottom: 10px;
}


.data-border {
    border-radius: 7px;
    box-shadow: 0 0 3px 0;
    padding: 15px;
    display: inline-block;
}


.personal-data-avatar {
    width: 100px;
    border-radius: 10px;
}


.personal-data-editor-container {
    display: flex;
    align-items: center;


}

.personal-data-select {
    width: 100%;
    height: 130px;
}

.personal-data-phone-container {
    margin-top: 30px;
    margin-bottom: 20px;
}

.personal-data-phone {
    margin-right: 20px;
}

.btn-data-edit {
    margin-right: 20px;

}

.data-btn {

    display: flex;
    align-items: center;
    justify-content: center;
    height: 20px;
    width: 20px;
    margin-left: 10px
}

.verification-buttons-container {
    margin-top: 20px;
}

.svg-closing-cross-lk {
    margin-left: 5px;
    cursor: pointer;
}

.svg-closing-cross-lk:hover {
    background-color: black;
    border-radius: 5px;
}


.data-dis-item {
    margin: 5px;
}

.dis-adder-n-save-indicator {
    display: flex;
    justify-content: space-between;
}

.chosen-discipline-container {
    display: flex;
    flex-flow: row wrap;
    align-items: start;
    align-content: flex-start;
    /*background-color: #ffe78e;*/
    background-color: white;
    border-radius: 10px;
    /*min-height: 10%;*/
    overflow-y: scroll;
    max-height: 600px;

}

.service-buttons-area {
    display: flex;
    flex-flow: row wrap;
    align-items: start;
    align-content: flex-start;

}

.service-button-container {
    display: flex;
    flex-direction: column;
    justify-content: end;
    border: 1px dotted gray;
    border-radius: 10px;
    padding: 5px;
    margin-right: 5px
}


.service-button {
    min-width: 100px;
    border-radius: 8px;
}


.personal-data-text-about-me {
    width: 100%;
    min-height: 20%;
    border-radius: 10px;
    /*background-color: #ffe78e;*/
    background-color: white;
}

.added-discipline {

    display: flex;
    align-items: center;
    border: 1px dotted gray;
    border-radius: 8px;
    margin: 5px;
    padding: 5px;

}


.save-button-container {
    display: flex;
    flex-direction: row;

    justify-content: flex-end;

}

.hide {
    display: none;
}

.block {
    display: block;
}

.inline {
    display: inline;
}


.invite-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}


.invite-table {

    background-color: #FFB703;
    padding: 30px;
    border-radius: 7px;
    box-shadow: 0 0 3px 0;
    margin-bottom: 10px;
    margin-top: 30px;
    min-width: 800px;

}

.invite-btn {
    width: 120px;
    margin-top: 20px;
    /*text-align: right;*/
}


.chat-form {
    margin-top: 10px;
    display: flex;
    align-items: center;

}

.chat-input {
    width: 95%
}

.chat-send-btn {
    color: white;
    border-radius: 8px;
    margin-left: 10px;
    align-self: flex-end;
    background-color: #0077b6;

}


.message-box-native-wrapper, message-box-moder-wrapper {
    /*width: 100%;*/
    /*min-height: 300px;*/
    /*max-height: 60vh;*/
    /*overflow-y: auto;*/
    /*margin-top: 20px;*/
    /*border-radius: 17px;*/


    width: 100%;
    height: 60vh;

    margin-top: 20px;
    border-radius: 8px;
    background-attachment: local;
    background-size: 100%;
    background-color: rgba(255, 255, 255, 0.97);
    background-blend-mode: lighten;

}

/*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++=*/


.message-box-native-wrapper-doer {



    width: 100%;
    height: 60vh;
    position: relative;
    margin-top: 20px;
    border-radius: 8px;
    background-attachment: local;
    background-size: 100%;
    /*background-color: rgba(255, 255, 255, 0.97);*/
    background-color: rgba(255, 255, 255, 0.97);
    background-blend-mode: lighten;
    /*display: flex;*/
    /*flex-direction: column;*/
}


.message-box-native-under, message-box-moder-under {
    /*box-shadow: 0 0 5px 0;*/
    border-radius: 20px 20px 20px 20px;
    overflow-y: auto;
    position: relative;
    /*border-radius: 17px;*/
    /*background-color: #8ecae6;*/
    padding: 10px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column-reverse;
    /*flex-direction: column;*/



}

/*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++=*/

.message-box-native, message-box-moder {

    /*border-radius: 20px 20px 20px 20px;*/
    /*padding: 0 20px;*/

    width: 100%;
    display: flex;
    flex-direction: column-reverse;
    /*background-image: url("https://blog.1a23.com/wp-content/uploads/sites/2/2020/02/pattern-28.svg");*/
    /*background-attachment: local;*/
    /*background-repeat: repeat-y;*/
    /*background-size: 100%;*/
    /*!*background-color: rgba(255, 255, 255, 0.97);*!*/
    /*background-color: rgba(255, 255, 255, 0.97);*/
    /*background-blend-mode: lighten;*/

}

.message-box-background {
    background-image: url("https://blog.1a23.com/wp-content/uploads/sites/2/2020/02/pattern-28.svg");
    background-attachment: local;
    background-repeat: repeat-y;
    background-size: 100%;
    /*background-color: rgba(255, 255, 255, 0.97);*/
    background-color: rgba(255, 255, 255, 0.97);
    background-blend-mode: lighten;
}

/*.message-box-native-empty {*/
/*    padding: 10px;*/
/*    border-radius: 20px 20px 20px 20px;*/
/*    width: 100%;*/
/*    height: 100%;*/
/*    display: flex;*/
/*    background-image: url("https://blog.1a23.com/wp-content/uploads/sites/2/2020/02/pattern-28.svg");*/
/*    background-repeat: repeat-y;*/
/*    background-size: 100%;*/
/*    background-color: rgba(255, 255, 255, 0.97);*/
/*    background-blend-mode: lighten;*/
/*}*/

/*.native-chat-main-message-wrapper {*/
/*    display: flex;*/
/*}*/



.native-chat-message-wrapper-left {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
}

.native-chat-message-wrapper-right {
    display: flex;

    align-items: flex-end;
    justify-content: flex-end;
}

.native-chat-message-wrapper-center {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: center;
}


.chat-message {
    word-wrap: break-word;

}

.chat-text {
    font-size: 1em;
}

.chat-right {
    /*box-shadow: 0 0 3px 1px gray;*/
    /*color: lightgray;*/
    /*background-color: #023e8a;*/
    background-color: #3a9dd8;
    padding: 10px 15px;
    margin-bottom: 5px;
    border-radius: 16px 16px 0px 16px;
    align-self: flex-end;
    max-width: 70%;
}

.chat-left {
    /*box-shadow: 0px 0px 5px 1px;*/
    /*background-color: #fba625;*/
    background-color: #f3f8fb;
    padding: 10px 15px;
    margin-bottom: 5px;
    border-radius: 16px 16px 16px 0px;
    align-self: flex-start;
    max-width: 70%;
}

.mc-date-username-holder {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    margin-bottom: 5px;
}


.chat-center {
    box-shadow: 0px 0px 5px 1px;
    background-color: darkgray;
    padding: 10px 15px;
    margin-bottom: 5px;
    border-radius: 17px;
    align-self: center;
    width: 50%;
}


.chat-username {
    display: flex;
    font-size: 0.7rem;
    font-weight: bold;
}

.chat-and-switch-buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.switch-button-container {
    display: flex;
    flex-direction: column;
    align-items: end;
    padding: 5px;
    margin-top: 20px;
    width: 30%;
}

.order-chat-user-buttons-n-notify-container{
    display: flex;
    padding-bottom: 10px;
    align-items: center;
    justify-content: end;
    width: 100%;
}

.user-switch-button {

    border-radius: 15px;
    width: 87%;



}

.invisible-chat-box {
    display: none;
}



.margin-auto {
    margin: auto;
}


.personal-data-portfolio-container {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}


.file-adder-and-roller-wrapper {
    width: 100%;
}


.filename-container {

    white-space: nowrap;
    overflow: hidden; /* Скрытие текста, который не помещается */
    text-overflow: ellipsis;
    margin-bottom: 0;
}



.terms-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    /*align-items: center;*/
    padding: 60px 30px 30px 30px;
    background-image: url("../img/tg_patterns/pattern-28.svg");
    background-repeat: repeat;
    background-color: rgba(255, 255, 255, 0.95);
    background-blend-mode: lighten;
    background-size: 50%;
}


.terms-tab {
    margin-left: 30px;
}