


.modal {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
    background-color: rgba(0,0,0,0.7);
    position: fixed;
    top: 0;
    left: 0;
    opacity: 0;
    transition: 0.5s;
    pointer-events: none;

}




.modal.active {
    opacity: 1;
    pointer-events: all;

}

.modal-link {
    cursor: pointer;
}


.modal-content-img{

    border-radius: 8px;
    padding: 20px;
    transform: scale(0.5);
    transition: 0.4s all;
    width: fit-content;

}

.modal-content-img.active{
    border-radius: 8px;
    padding: 20px;
    transform: scale(1);
    transition: 0.4s;
    width: fit-content;
}


.modal-content{

    border-radius: 8px;
    padding: 20px;
    transform: scale(0.5);
    transition: 0.4s all;
    width: 400px;
    height: fit-content;

}


.modal-content.active{
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 8px;
    padding: 20px;
    transform: scale(1);
    transition: 0.4s;
    width: 400px;
}

.back-of-viewer {
    background-color: white;
    padding: 30px;
    border-radius: 20px;
}

.back-of-doc-viewer {
    width: 100%;
    height: 100%;
    background-color: white;
    padding: 20px 30px 30px 30px;
    /*border-radius: 20px;*/
}


.fileviewer-top {
    display: flex;
    justify-content: space-between;
    align-items: center;

}

.modal-100.active {
    /*border-radius: 8px;*/
    /*padding: 20px;*/
    transform: scale(1);
    transition: 0.4s;
    background-color: white;
    width: 100%;
    height: 100vh;
    background-color: rgba(255, 255, 255, 0);
}


.modal-content-nested-under {
    max-height: 80%;
    width: 600px;
    border-radius: 15px;
    overflow-y: auto;
}

.modal-content-nested{

    display: flex;
    flex-direction: column;
    /*border-radius: 7px;*/
    padding: 20px;
    transform: scale(0.5);
    transition: 0.4s all;
    /*width: 400px;*/
    background-color: white;


}

.modal-content-nested.active{
    display: flex;

    /*border-radius: 7px;*/
    padding: 40px;
    transform: scale(1);
    transition: 0.4s;

    max-height: 60%;

}



.nested-modal-content-top {
    padding-bottom: 20px;
}


.modal-content-nested-bottom {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
}

.back-nested-btn {
    margin-left: 20px;
    border-radius: 8px;
    /*position: absolute;*/
    /*top: 80%;*/
    /*left: 80%;*/
    /*transform: translate(-50%, -50%);*/
}








.decision-btn-container{

    display: flex;
    justify-content: center;
}


.decision-btn {


    width: 100px;
    margin: 5px;

}


.assign-message {
    font-family: 'Roboto';

    font-size: 1.3rem;
    text-align: center;
}


.modal-message{
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 1.2rem;
    text-align: center;
}

.modal-message-red{
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 1rem;
    text-align: left;
    background-color: #ff4603;
    padding: 10px;
    border-radius: 8px;
}


.modatTextArea{
    width: 100%;
}


.nested-message-name-n-avatar-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-top: 5px;
}



.nested-message-in-modal {
    /*align-self: flex-end;*/
    display: flex;
    flex-direction: column;
    /*align-items: end;*/
    justify-content: flex-end;
    background-color: #8ac6e6;
    padding: 5px 15px 5px 15px;
    margin-top: 5px;
    border-radius: 8px;
    min-width: 250px;
    /*max-width: 95%;*/
    word-wrap: break-word
}


.forwarded-message-nested {
    background-color: #ffe78e;
    padding: 7px;
    border-radius: 8px;
    margin-bottom: 3px;
    margin-top: 5px;
    max-width: 75%;
    width: fit-content;
    display: flex;
    flex-direction: column;


}


.forward-message-date {
    margin: 0;
    font-size: 0.7rem;
    align-self: end;
}


.nested-message-text {
    margin-bottom: 0;
    margin-top: 10px;
    align-self: end;
}

