.modal-creator-input {
    width: 100%;
    margin-top: 5px;
}

.user-chat-adder-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 5px;
}

.modal-message-creator{
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 1.2rem;
    text-align: center;
    white-space: pre-line
}

.admin-btn {
    min-width: 110px;
}

.cheater-processor-wrapper {
    display: flex;
}

.cheater-adder-container {
    width: 40%;
    display: flex;
    flex-direction: column;
}

.cheater-adder {
    display: flex;
    flex-direction: column;
    padding: 15px;
    background-color: #fbc025;
    border-radius: 10px;
}

.cheater-adder-input {
    margin-bottom: 5px;
}


.cheater-add-btn-container {
    width: fit-content;
    align-self: flex-end;
    margin-top: 10px;
    display: flex;
    align-items: flex-end;
}

.cheater-add-btn {

    align-self: flex-end;
    width: 100px;
}




.cheater-search-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 60%;
}


.cheater-search-results {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 0 30px;
}


.founded-cheater-tab {
    border-radius: 15px;
    box-shadow: 0 0 3px 0;
    width: 80%;
    background-color: #219ebc;
    padding: 20px;
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    /*align-items: center;*/
    /*justify-content: space-between;*/
}

.founded-cheater-tab-top {
    border-radius: 15px;
    background-color: #219ebc;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.founded-cheater-tab-bottom {
    /*border-radius: 15px;*/
    background-color: #219ebc;
    display: flex;
    flex-direction: column;
    /*align-items: center;*/
    /*justify-content: center;*/
    color: white;
}

.cheater-manage-btn-container {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}

.cheater-edit-btn {
    margin-top: 20px;
    align-self: flex-end;
    margin-left: 10px;
}



.order-table-th, .order-table-td {
    max-width: 150px; /* Примерная максимальная ширина столбца */
    overflow: hidden;
    text-overflow: ellipsis; /* Для обрезания текста, если он не помещается */
    white-space: nowrap; /* Запрещает перенос текста на новую строку */
}

.open-order-admin-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20px;
    font-size: 12px;

}



.fake-save-btn {
    align-self: flex-end;
    margin-bottom: 10px;
}


.admin-review-tab {
    background-color: white;
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 10px;
}