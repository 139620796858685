


.btn-primary-poor{
    background-color: rgba(13, 110, 253, 0.66);
    color: white;
}



.white {
    padding: 3px;
    border-radius: 5px;
    background-color: #ffffff;
    /*color: white;*/
}



.black-request{
    padding: 5px;
    border-radius: 8px;
    font-weight: bold;
    background-color: #ffffff;
    color: #000000;
}


.blue-request{
    padding: 5px;
    border-radius: 8px;
    color: #3a9dd8;
    font-weight: bold;
    background-color: #ffffff;
    /*color: #29de10;*/
}

.green-request{
    padding: 5px;
    border-radius: 8px;
    background-color: #ffffff;
    font-weight: bold;
    color: #42df13
    /*color: #29de10;*/
}

.red-request {
    padding: 5px;
    border-radius: 8px;
    background-color: #ffffff;
    font-weight: bold;
    color: #f1293d
    /*color: #f51549;*/
}

.closed-request{
    padding: 5px;
    border-radius: 8px;
    background-color: #000000;
    font-weight: bold;
    color: #ffffff
    /*color: #29de10;*/
}


.green-event {
    color: #42df13
}

.red-event {
    color: #f1293d
}

.blue-event {
    color: #3a9dd8;
}


.ubuntu, h1, h2, h3, h4, h5, h6 {
    font-family: 'Ubuntu', sans-serif;
}


.pointer {
    cursor: pointer;
}


.native-blue {
    color: #126782;
}

.margin-top-5{
    margin-top: 5px;
}

.margin-top-10{
    margin-top: 10px;
}

.margin-top-20{
    margin-top: 20px;
}

.margin-bottom-20{
    margin-bottom: 20px;
}

.margin-bottom-10{
    margin-bottom: 10px;
}

.margin-right-20{
    margin-right: 20px;
}

.margin-right-10 {
    margin-right: 10px;
}

.margin-left-50 {
    margin-left: 50px;
}

.margin-left-30 {
    margin-left: 30px;
}

.margin-left-20 {
    margin-left: 20px;
}

.margin-left-10 {
    margin-left: 10px;
}

.margin-0 {
    margin: 0;
}

.padding-5 {
    padding: 5px;
}

.padding-10 {
    padding: 10px;
}

.padding-20 {
    padding: 20px;
}

.padding-right-50{
    padding-right: 50px;
}


.radius-10 {
    border-radius: 10px;
}


.width-50 {
    width: 50%;
}

.width-60 {
    width: 60%;
}

.width-80 {
    width: 80%;
}

.none {
    display: none;
}


.red {
    color: red;
}

.red-for-gray {
    color: rgba(255, 0, 0, 0.6);
}

.red-for-blue {
    color: rgb(232, 51, 51);
}




.white-color {
    color: white;
}

.gold-color {
    color: gold;
}


.gray-color {
    color: slategray;
}

.orange-color {
    color: orange;
}


/*.gray-back {*/
/*    background-color: #adacac;*/
/*}*/




.green-color{
    /*color: #599156*/
    color: #4bc23e
}

.blue-color{
    color: #267ad4
}



.maintenance-order{

    box-shadow: 0 0 3px 0;
    border-radius: 7px;
    padding: 20px;
    margin-bottom: 30px;
    width: 100%;
    min-height: 100px;
    background: repeating-linear-gradient(
            45deg,
            #000000,
            #000000 40px,
            #eaca2e 40px,
            #eaca2e 80px
    );

}


.empty-message-wrapper{

    width: fit-content;
    /*max-width: 60%;*/
    box-shadow: 0 0 3px 0;
    border-radius: 7px;
    padding: 20px;
    margin-bottom: 30px;
    min-height: 100px;
    background-color: #0077b6;
    height: fit-content;
    align-self: center;
    /*background: repeating-linear-gradient(*/
    /*        45deg,*/
    /*        #000000,*/
    /*        #000000 40px,*/
    /*        #eaca2e 40px,*/
    /*        #eaca2e 80px*/
    /*);*/

}



.gray-back{
    background-color: lightslategray;
}

.red-back {
    background-color: rgba(255, 0, 0, 0.8)
}

.orange-back {
    background-color: orange;
}

.green-back {
    background-color: #4bc23e;
}

.blue-back{
    background-color: #90e0ef
}


.white-back-search {
    background-color: white;
}

.white-back{
    background-color: white;
    border-radius: 8px;

}


.absolute{
    position: absolute;

}

.inline-block {
    display: inline-block;
}

.border-1-gray{
    border: 1px gray solid
}

.border-2{
    border: 2px black solid
}

.border-3-blue {
    border: 3px #267ad4 solid

}


.border-off{
    border: 0px;
}


.blinking {
    animation: blinker 1.5s linear infinite;
}


.pay-form-wrapper{
    background-image: url("../img/tg_patterns/pattern-28.svg");
    background-repeat: repeat;
    background-color: rgba(255, 255, 255, 0.95);
    background-blend-mode: lighten;
    background-size: 50%;
    padding-top: 30px;
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    margin-right: auto;
    margin-left: auto;
}

.sticky-note {
    background-color: #ffe78e;
}

.align-text-center {
    text-align: center;
}

.centrator-of-one{
    display: flex;
    justify-content: center;
    align-items: center;
}

.margin-for-decision {
    margin: 0;
    margin-left: 15px;
}


.bold {
    font-weight: bold;
}

.normal {
    font-weight: normal;
}


.rem-13 {
    font-size: 1.3rem;
}

.rem09 {
    font-size: 0.9rem;
}

.rem08 {
    font-size: 0.8rem;
}

.rem07 {
    font-size: 0.7rem;
}

#user-list {
    transition: transform 0.5s ease;
    transform-style: preserve-3d;
}

#full-user-list {
    transition: transform 0.5s ease;
    /*transform-style: preserve-3d;*/
}


.rotated-180 {
    transform: rotateY(180deg);
}

.rotated-0 {
    transform: rotateY(0deg);
}



.display-flex {
    display: flex;
}


.fixed-arrow-at-container {
    cursor: pointer;
    align-self: end;
    position: absolute;
    /*background-color: rgba(255, 255, 255, 0.4);*/
    /*border-radius: 50px;*/
    /*border: 1px solid rgba(128, 128, 128, 0.16);*/
    /*padding: 10px;*/
    /*margin-right: 10px;*/
    /*margin-bottom: 10px;*/
    z-index: 300;
}

.fixed-arrow {
    /*cursor: pointer;*/
    /*align-self: end;*/
    /*position: absolute;*/
    background-color: rgba(255, 255, 255, 0.4);
    border-radius: 50px;
    border: 1px solid rgba(128, 128, 128, 0.16);
    padding: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
    z-index: 500;
}




.fixed-arrow:hover, .fixed-arrow-moder:hover, .fixed-arrow-user:hover {
    background-color: rgb(225, 225, 225);
}


.fixed-arrow-moder {
    cursor: pointer;
    align-self: flex-end;
    position: absolute;
    background-color: rgba(255, 255, 255, 0.4);
    border-radius: 50px;
    border: 1px solid rgba(128, 128, 128, 0.16);
    padding: 10px;
    margin-right: 30px;
    margin-bottom: 10px;
    z-index: 500;
}


.fixed-arrow-user {
    cursor: pointer;

    position: absolute;
    bottom: 5px; /* Отступ от нижней границы */
    right: 5px; /* Отступ от правой границы */
    background-color: rgba(255, 255, 255, 0.4);
    border-radius: 50px;
    border: 1px solid rgba(128, 128, 128, 0.16);
    padding: 10px;
    margin-right: 30px;
    margin-bottom: 10px;
    z-index: 500;
}



.hints-on-blue {
    margin: 0;
    font-size: 0.6rem;
    color: lightgray;
}

.hints-on-light-blue {
    margin: 0;
    font-size: 0.6rem;
    color: whitesmoke;
}

.hints-on-orange {
    margin: 0;
    font-size: 0.6rem;
    color: gray;
}


.no-wrap-container {
    white-space: nowrap;
}


.line-through {
    text-decoration: line-through;
}






.pre-line {
    white-space: pre-line
}

.flex-container-row{
    display: flex;
    flex-direction: row;
    justify-content: end;
}

.flex-container-column{
    display: flex;
    flex-direction: column;
    justify-content: end;
}

.nowrap {

    white-space: nowrap;
}


.underline {
    text-decoration: underline;

}

.none-decoration {
    text-decoration: none;
    color: black;
}


.light-blue {
    background-color: #3a9dd8;
}


.highlight-blue {
    background-color: #0d99fd;
    font-weight: bold;
}

.highlight-orange {
    background-color: #fbc025;
    font-weight: bold;
}


.width-100p {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex: 1; /* Используйте flex-grow для заполнения доступного пространства */
    overflow: hidden; /* Обрезает содержимое, которое не помещается */
}




.no-margin {
    margin: 0
}

.flex-between {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 5px;
    padding-bottom: 5px;

}

.flex-start {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-top: 5px;
    padding-bottom: 5px;

}

.sms-call {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.black {
    color: black
}


.align-self-left {
    align-self: flex-start;
}

.testing-room{
    margin: 0px 110px;
}





.fit-content-w {
    width: fit-content;
}


.border-radius-8 {
    border-radius: 8px;
}


.agent-link {
    color: black;
    text-decoration: none;
}

.agent-link:hover {
    color: #0077b6;
}

.blue-theme-back {
    background: linear-gradient(to left, #3a9dd8 0%, rgba(255, 255, 255, 0) 50%);
}

.td-flex-row {
    display: flex;
    align-items: center;
}