.ws-chat-canvas-under::-webkit-scrollbar {
    width: 5px;               /* ширина scrollbar */
}
.ws-chat-canvas-under::-webkit-scrollbar-track {
    /*background: wheat;        !* цвет дорожки *!*/

    /*background: rgba(202, 240, 248, 0);*/
    background: #ffffff; /* цвет дорожки */
    /*background: #fba625;*/
}

.ws-chat-canvas-under::-webkit-scrollbar-thumb {

    background-color: rgba(128, 128, 128, 0.14); /* цвет плашки */
    /*background-color: #219ebc;    !* цвет плашки *!*/
    border-radius: 8px 8px 8px 8px; /* закругления плашки */
    /*border: 1px solid wheat;  !* padding вокруг плашки *!*/
}





.ws-chat-arbitr-canvas-under::-webkit-scrollbar {
    width: 5px;               /* ширина scrollbar */
}
.ws-chat-arbitr-canvas-under::-webkit-scrollbar-track {
    /*background: wheat;        !* цвет дорожки *!*/
    background: #ffffff;
}

.ws-chat-arbitr-canvas-under::-webkit-scrollbar-thumb {
    background-color: rgba(128, 128, 128, 0.14); /* цвет плашки */
    border-radius: 8px 8px 8px 8px;       /* закругления плашки */
    /*border: 1px solid wheat;  !* padding вокруг плашки *!*/
}


.ws-chat-flex {
    display: flex;
}


.ws-chat-wrapper {

    width: 75%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 10px;
    /*box-shadow: 0 0 3px 0;*/


}

.ws-chat-embedded-wrapper {

    max-height: 92.5vh;
    /*width: 75%;*/
    /*margin-left: auto;*/
    /*margin-right: auto;*/
    /*border: 3px red solid;*/

    display: flex;
    flex-direction: column;
    /*margin-bottom: 20px;*/
    /*height: 92.5vh;*/
    width: 80%;
    align-items: flex-start;

    border-left: 1px gray solid;
    border-image: linear-gradient(to bottom, rgba(128,128,128,0) 0%, rgba(128,128,128,1) 50%, rgba(128,128,128,0) 90%);
    border-image-slice: 1;





}


/*.ws-chat-embedded-main {*/
/*    scrollbar-color: rgba(128, 128, 128, 0.14) #ffffff;*/
/*    scrollbar-width: thin;*/
/*    width: 80%;*/
/*    height: 92.5vh;*/
/*    border: black 1px solid;*/

/*}*/



.shadow-helper {
    border-radius: 10px;
    /*box-shadow: 0 0 3px 0;*/
}

.ws-chat-main{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 0px 0px 0px 0px;
    border-radius: 10px 10px 0px 0px;
    /*box-shadow: 0 0 3px 0;*/
    height: 75vh;

    /*min-height: 80vh;*/

    /*background-color: #90e0ef;*/
    /*background-color: #fba625;*/

}


.ws-chat-embedded-main{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 0px 0px 0px 0px;
    /*border-radius: 10px 10px 0px 0px;*/
    /*box-shadow: 0 0 3px 0;*/
    height: 92.5vh;


    /*overflow: hidden;*/

}



.ws-chat-embedded-main-top{
    display: flex;
    flex: 1;
    min-height: 0;
    height: 80.5vh;
}



.ws-bottom-part {
    /*border: solid red 3px;*/
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 0px 20px 20px 20px;
    border-radius: 0px 0px 10px 10px;
    height: auto;
}


.ws-embedded-bottom-part {
    /*border: solid red 3px;*/
    /*position: relative;*/
    /*width: 100%;*/
    /*display: flex;*/
    /*height: fit-content;*/

    /*border: 3px red solid;*/
    display: flex;

    align-items: flex-start;
    margin-top: 10px;
    width: 100%;
    position: relative;


}


.wc-chat-empty-canvas {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url("https://blog.1a23.com/wp-content/uploads/sites/2/2020/02/pattern-28.svg");
    background-attachment: local;
    background-repeat: repeat-y;
    background-size: 100%;
    background-color: rgba(255, 255, 255, 0.96);
    background-blend-mode: lighten;
}


.ws-chat-canvas-under, .ws-chat-arbitr-canvas-under  {
    display: flex;
    flex-direction: column-reverse;
    /*border-radius: 15px;*/
    width: 100%;
    height: 100%;
    /*background-color: #ffffff;*/
    overflow-y: auto;
    overflow-x: hidden;
    border-left: 1px #ececec solid;

    background-image: url("https://blog.1a23.com/wp-content/uploads/sites/2/2020/02/pattern-28.svg");
    background-attachment: local;
    background-repeat: repeat-y;
    background-size: 100%;
    background-color: rgba(255, 255, 255, 0.96);
    background-blend-mode: lighten;

}


.ws-chat-embedded-canvas-under-wrapper {
    width: 80%;
}


.ws-chat-embedded-canvas-under  {
    display: flex;
    flex-direction: column-reverse;
    /*border-radius: 15px;*/
    width: 100%;
    height: 100%;
    margin-bottom: 10px;

    /*background-color: #ffffff;*/
    overflow-y: auto;
    overflow-x: hidden;




    /*border-left: 1px #ececec solid;*/

    /*border-image: linear-gradient(to bottom, rgba(128,128,128,0) 0%, rgba(128,128,128,1) 50%, rgba(128,128,128,0) 90%);*/
    /*border-image-slice: 1;*/

    background-image: url("https://blog.1a23.com/wp-content/uploads/sites/2/2020/02/pattern-28.svg");
    background-attachment: local;
    background-repeat: repeat-y;
    background-size: 100%;
    background-color: rgba(255, 255, 255, 0.96);
    background-blend-mode: lighten;

}



.ws-chat-canvas{
    padding: 0px;
    width: 100%;
    /*border-radius: 15px;*/

    display: flex;
    flex-direction: column-reverse;
    background-image: url("https://blog.1a23.com/wp-content/uploads/sites/2/2020/02/pattern-28.svg");
    background-attachment: local;
    background-repeat: repeat-y;
    background-size: 100%;
    background-color: rgba(255, 255, 255, 0.96);
    /*background-color: rgba(246, 246, 246, 0.98);*/
    background-blend-mode: lighten;
}



.ws-chat-message-wrapper{

    display: flex;
    flex-direction: column-reverse;
    width: 100%;
    padding: 5px 5px 0px 5px;
}

.ws-chat-right .ws-chat-link{
    text-decoration: none;
    color: wheat;
}

.ws-chat-left .ws-chat-link{
    text-decoration: none;
    color: black;
}


.ws-chat-right{
    display: flex;
    flex-direction: column;
    /*justify-content: flex-end;*/
    /*align-items: flex-end;*/
    text-align: start;

    /*box-shadow: 0 0 3px 1px gray;*/
    /*color: lightgray;*/

    background-color: #8ac6e6;
    /*background-color: #3a9dd8;*/
    /*background-color: #29aad2;*/
    padding: 7px 15px;
    margin-bottom: 5px;
    border-radius: 17px 17px 0px 17px;
    align-self: flex-end;
    min-width: 20%;
    max-width: 75%;
    font-size: 1rem;
    margin-right: 5px;



}

.ws-chat-left{
    /*box-shadow: 0px 0px 5px 1px;*/


    background-color: #f3f8fb;
    /*background-color: #fbc025;*/
    padding: 7px 15px;
    margin-bottom: 5px;
    border-radius: 17px 17px 17px 0px;
    align-self: flex-start;
    min-width: 20%;
    max-width: 75%;
    font-size: 1rem;
    margin-left: 5px;
}


.text-ws-container {
    font-size: 0.85rem;
}

.sound-and-search-container {
    display: flex;
    justify-content: space-between;
    width: 3%;



}

.ws-chat-compose-form{

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    margin-top: 15px;
    /*border: 2px blue solid;*/
    /*overflow: hidden;*/

}

.ws-ballast {
    /*width: 80%;*/
}

.textarea-and-invocation-list-container{

    width: 80%;
    margin-right: 20px;
    z-index: 1000;
    padding: 0;
}




.user-list-container {
    position: absolute;
    bottom: 30px;
    width: 40vw;
    max-height: 300px;
    overflow-y: scroll;
    /*left: 0!* ваше значение *!;*/
    background-color: white/* ваш цвет фона */;
    border: 1px solid /* ваш цвет рамки */;
    padding: 5px/* ваше значение */;
    z-index: 1; /* Гарантирует, что список пользователей будет поверх других элементов */
}

.user-list-container > p {
    margin: 0;
}


.invocation-user-and-avatar-container, .potential-doer-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 5px;
    padding-bottom: 5px;
}

.invocation-user-and-avatar-container:hover {
    background-color: rgba(0, 0, 0, 0.25);
    cursor: pointer;
}


.ws-chat-send-button{
    background-color: #0077b6;
    margin-top: 10px;
}


.ws-chat-input{
    width: 100%;
    margin: 0;
    resize: none;
    height: auto;
    border-radius: 8px;
    vertical-align: top;


}

.ws-chat-input-search{
    width: 100%;
    margin: 0;
    resize: none;
    height: auto;
    border-radius: 8px;
    vertical-align: top;
    border: 1px solid rgb(232, 51, 51);
}

.ws-chat-input-search:focus {
    outline: none !important;
    border: 2px solid rgb(232, 51, 51);

}

@-moz-document url-prefix() {
    .ws-chat-input, .private-chat-text-input {
        overflow-x: hidden;
    }

}


.ws-chat-input-archive{
    width: 100%;
    /*margin-top: 10px;*/

    resize: none;
    height: auto;
    overflow: hidden;

}



.ws-chat-input-file-wrapper{
    margin-top: 10px;
    padding: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    /*width: 15%;*/

}

.ws-chat-embedded-input-file-wrapper{
    /*margin-top: 10px;*/
    /*padding: 5px;*/
    /*display: flex;*/
    /*flex-direction: row;*/
    /*align-items: center;*/
    /*justify-content: space-around;*/
    /*width: 15%;*/

}


#ws-chat-input-file{
    display: none;

}


.ws-input-file-icon-wrapper{

    cursor: pointer;
    margin-right: 5px;
}

.ws-input-file-indicator{
    font-family: 'Ubuntu', sans-serif;
    font-weight: bold;
}


.full-image-view {
    max-width: 90vw;
    max-height: 90vh;

}

.z-999 {
    z-index: 9999;
}


.ws-embedded-search-message-control-container {
    display: flex;
    flex-direction: row;
    /*padding: 3px;*/
    /*border-radius: 10px;*/
    /*align-items: center;*/
    /*justify-content: flex-start;*/
    /*width: 170px;*/
    /*margin-bottom: 2px;*/

}


.chat-pre-image{
    min-height: 30px;
    max-width: 120px;
    margin: 10px;
    border: 2px solid lightcyan;
    border-radius: 5px;
    cursor: pointer;
}

.ws-chat-image{
    width: 120px;
    margin: 10px;
    border: 2px solid lightcyan;
    border-radius: 5px;
}


.ws-chat-delete-image{
    border: 1px solid lightcyan;
    border-radius: 100px;
    background-color: coral;
    margin-left: 10px;
}


.ws-message-avatar{
    width: 35px;
    height: 35px;
    margin-right: 10px;
    border-radius: 100px;

}


.ws-message-container-right{
    /*word-wrap: break-word;*/
    display: flex;
    overflow-wrap: anywhere;
    flex-direction: column;
    align-items: flex-end;



}

.ws-message-container-left{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /*word-wrap: break-word;*/
    overflow-wrap: anywhere;

}


.ws-chat-message-date-container {
    display: flex;
    justify-content: flex-end;
}

.ws-chat-message-date{

    font-size: 0.65rem;
    font-weight: normal;

}


.ws-chat-error-message{
    color: red;
    margin-top: 20px;
}


.ws-chat-hr{

    /*height: 36px;*/
    margin-bottom: 0;
    color: slategray;
    position: relative;
    z-index: 10;
    overflow: hidden;
    text-align: center;
}

.ws-chat-hr:before,
.ws-chat-hr:after {

    content: '';
    display: inline-block;
    width: 100%;
    height: 1px;
    background: #000;
    z-index: -10;
    position: relative;
    vertical-align: middle;
}
.ws-chat-hr:before {
    background-color: darkgray;
    right: 15px;
    margin: 10px 0 10px -100%;

}
.ws-chat-hr:after {
    background-color: darkgray;
    left: 15px;
    margin: 10px -100% 10px 0;
}

.ws-chat-link{
    /*color: lightyellow;*/
}


.ws-chat-image-static {
    margin: 20px;
    width: 80px;
}


.ws-chat-wrapper h6{
    display: inline;

}

.ws-chat-arrow-svg {
    width: 25px;
    margin-left: 5px;
    margin-right: 5px;
    display: none

}

.ws-chat-arrow-svg:hover {
    width: 30px;
    margin-right: 0;
    cursor: pointer;

}



.wrapper-for-arrow:hover .ws-chat-arrow-svg {
    display: block;

}


.wrapper-for-arrow-left {
    display: flex;
    flex-direction: row;
    align-items: end;
    justify-content: start;
    padding-left: 20px;
}

.wrapper-for-arrow-right {
    display: flex;
    flex-direction: row;
    align-items: end;
    justify-content: end;

}


.ws-answering-message-wrapper {
    display: flex;
    padding: 10px 10px 10px 10px;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    max-width: 75%; /* ограничение ширины до 50% ширины родителя */
    align-self: flex-start; /* выравнивание по левому краю */
    width: auto; /* ширина зависит от содержимого */
}


.ws-chat-send-button-container {
    /*display: flex;*/
    /*flex-direction: row;*/
    /*align-items: flex-end;*/
    /*max-width: 40%;*/
}


.ws-forwarding-list-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.ws-forwarding-list-top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 20px;
    height: 20%;
    align-items: flex-start;


}

.ws-forwarding-list-bottom {
    display: flex;
    padding: 5px;
    justify-content: center;
    flex-wrap: wrap;
    overflow-y: auto;
}


.ws-forwarding-contact-button {
    min-width: 30%;
    margin: 3px;
}

.drop-area {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    border: 5px dotted black;
    margin-top: 20px;
}

.writing-container {

    padding: 0 10px;
    height: 35px;

    display: flex;
    width: 100%;
}

.somebody-writing{
    display: flex;
    width: 100%;
    /*height: 1px;*/
    /*margin-left: 20px;*/
    margin-top: 0px;
    margin-bottom: 0;
    align-items: end;
    padding: 0;
}

.somebody-writing-private{
    display: flex;
    width: 100%;
    height: 10px;
    margin-left: 0px;
    /*margin-top: 15px;*/
    margin-bottom: 0;
    align-items: center;
    padding-bottom: 10px;
}


.ws-forward-and-close-buttons-container {
    display: flex;
    align-items: center;
    white-space: nowrap;
}


.chat-forward-container {
    display: flex;
    align-items: flex-end;
    width: fit-content;
}


.ws-search-hint {
    padding-left: 35px
}

.ws-embedded-search-hint {
    padding-left: 0
}


.arbitr-user-link {
    color: black;
    text-decoration: none;
    /*font-weight: bold;*/
    white-space: nowrap;
}

.ws-chat-embedded-canvas-under::-webkit-scrollbar {
    width: 5px;               /* ширина scrollbar */
}
.ws-chat-embedded-canvas-under::-webkit-scrollbar-track {
    /*background: wheat;        !* цвет дорожки *!*/

    /*background: rgba(202, 240, 248, 0);*/
    background: #ffffff; /* цвет дорожки */
    /*background: #fba625;*/
}

.ws-chat-embedded-canvas-under::-webkit-scrollbar-thumb {

    background-color: rgba(128, 128, 128, 0.14); /* цвет плашки */
    /*background-color: #219ebc;    !* цвет плашки *!*/
    border-radius: 8px 8px 8px 8px; /* закругления плашки */
    /*border: 1px solid wheat;  !* padding вокруг плашки *!*/
}


.ws-forwarding-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px
}

.ws-forwarding-cancel-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    border-radius: 8px;
}